import {
  ExceptionOutlined,
  PrinterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Row, Table, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useReactToPrint } from "react-to-print";
import { Fixed2, rangePresets } from "../../../../common/utils/common.utils";
import { SelectVendors } from "../../../../components/common/FormItem/SelectCustomFeilds";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import FormHeaderTitle from "../../../Invoice(Visa)/Components/FormHeaderTitle";
import {
  useGetVendorWisePurchaseReportQuery,
  useLazyGetVendorWisePurchaseReportExcelQuery,
} from "../endpoints/VendorWisePurchase_PaymentEndpoints";
import { IVendorWisePurchase_PaymentFormData } from "../types/VendorWisePurchase_PaymentTypes";
import { VendorWisePurchaseUtils } from "../utils/VendorWisePurchase_PaymentUtils";
import { VendorWisePaymentUtils } from "../utils/VendorWisePaymentUtils";
import { TextAreaInput } from "../../../../components/common/FormItem/FormItems";

const VendorWisePurchaseAndPayment = () => {
  const [getData, SetGetData] = useState(false);
  const toDate = dayjs().format("YYYY-MM-DD");
  const [date, setDate] = useState({ toDate: toDate, fromDate: toDate });
  const [vendor, setVendor] = useState("all");
  const [form] = Form.useForm();
  const location = useLocation();

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const [fetchExcel] = useLazyGetVendorWisePurchaseReportExcelQuery();
  const { data, isLoading, isFetching } = useGetVendorWisePurchaseReportQuery(
    {
      ...pagination,
      formDate: date.fromDate,
      todDate: date.toDate,
      vendor: vendor,
    },
    { skip: getData }
  );

  const downloadExcel = () => {
    fetchExcel({ formDate: date.fromDate, todDate: date.toDate, id: vendor });
  };

  useEffect(() => {
    form.setFieldValue("date_range", [dayjs(), dayjs()]);
    form.setFieldValue("invoice_combvendor_id", "all");
  }, []);

  // This useEffect is working based on Params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const dailyValue = searchParams.get("daily");

    const monthFormDate = searchParams.get("from_date");
    const monthToDate = searchParams.get("to_date");

    const yearFormDate = searchParams.get("y_from_date");
    const yearToDate = searchParams.get("y_to_date");

    if (dailyValue) {
      onFinish({
        date_range: [dailyValue, dailyValue],
        invoice_combvendor_id: "all",
      });
    }

    if (monthFormDate && monthToDate) {
      form.setFieldValue("date_range", [
        dayjs(monthFormDate),
        dayjs(monthToDate),
      ]);
      onFinish({
        date_range: [monthFormDate, monthToDate],
        invoice_combvendor_id: "all",
      });
    }

    if (yearFormDate && yearToDate) {
      form.setFieldValue("date_range", [
        dayjs(yearFormDate),
        dayjs(yearToDate),
      ]);
      onFinish({
        date_range: [yearFormDate, yearToDate],
        invoice_combvendor_id: "all",
      });
    }
  }, [location.search]);

  const onFinish = async (values: IVendorWisePurchase_PaymentFormData) => {
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");

    setVendor(values.invoice_combvendor_id);
    setDate({ fromDate: setFrom_date, toDate: setTo_date });

    SetGetData(false);
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Vendor_Wise_Payment_Purchase_Report`,
  });

  const common_style: React.CSSProperties = {
    textAlign: "right",
    marginRight: "10px",
    fontWeight: "bold",
  };

  // @HANDLE CONTENTS
  const jsx_com = (
    <div>
      <FormHeaderTitle title="Ticket Purchase From Vendor" />

      <Table
        size="small"
        bordered
        dataSource={data?.data?.purchase}
        columns={VendorWisePurchaseUtils({ pagination })}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ["20", "50", "100", "200", "500"],
                onChange: handlePaginationChange,
              }
        }
        summary={(pageData) => {
          let purchaseTotal = 0;
          let dueTotal = 0;
          let vendorTotal = 0;

          pageData.forEach(({ cost_price, due_amount, payment_amount }) => {
            purchaseTotal += Number(cost_price);
            dueTotal += Number(due_amount);
            vendorTotal += Number(payment_amount);
          });
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={3} colSpan={2} />
                <Table.Summary.Cell index={3} colSpan={5}>
                  <Typography.Text strong>Total</Typography.Text>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={7}>
                  <Typography style={common_style}>
                    {Fixed2(data?.data?.summary.total_cost || 0)}
                  </Typography>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />

      <FormHeaderTitle title="Payment List" />

      <Table
        size="small"
        bordered
        dataSource={data?.data?.payment}
        columns={VendorWisePaymentUtils()}
        pagination={false}
      />
    </div>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: "", title: "Vendor Wise Purchase And Payment" }}
        extraInfo={{
          date:
            `${dayjs(date.fromDate).format("DD-MM-YYYY")} to ${dayjs(
              date.toDate
            ).format("DD-MM-YYYY")}` || "",
        }}
      />
    </div>
  );

  return (
    <>
      <div>
        <BreadCrumb
          arrOfOption={["Report", "Vendor Wise Purchase And Payment"]}
        />

        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row justify={"space-between"}>
            <Col sm={24} md={12} lg={12}>
              <div style={{ display: "flex", gap: 10 }}>
                <SelectVendors
                  name="invoice_combvendor_id"
                  size={4}
                  placeholder="Select Vendor"
                  offDropDown
                  showAll
                />

                <Form.Item name="date_range">
                  <DatePicker.RangePicker
                    presets={rangePresets}
                    format={"DD-MM-YYYY"}
                    allowClear={false}
                  />
                </Form.Item>

                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
              </div>
            </Col>

            <Col style={{ display: "flex", gap: 10 }}>
              <Button type="primary" onClick={handlePrint}>
                <PrinterOutlined />
                Print
              </Button>
              <Button onClick={downloadExcel} type="primary">
                <ExceptionOutlined />
                Excel
              </Button>
            </Col>
          </Row>
        </Form>

        <Typography.Text style={{ margin: "5px 0px" }}>
          {/* You have total: {data?.count} rows */}
        </Typography.Text>

        {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
        {jsx_com}

        {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
        {print_content}
      </div>
    </>
  );
};

export default VendorWisePurchaseAndPayment;
