import { Button, Popconfirm, Space, Tag, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import {
  crud,
  IPermission,
  ISetState,
} from "../../../../common/types/commonTypes";
import { ProductDataType, IProductsState } from "../types/productTypes";
import { useDeleteProductMutation } from "../endpoints/productsEndpoints";
import { toasterNotification } from "../../../../common/utils/ToasterNotification";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../auth/states/userSlice";

type Props = {
  pagination: {
    current: number;
    pageSize: number;
  };
  setEditInfo: ISetState<ProductDataType | null>;
  showModal: () => void;
  permission?: IPermission;
};

export const columns = ({
  pagination,
  setEditInfo,
  showModal,
  permission,
}: Props): ColumnsType<ProductDataType> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useSelector(selectUser);
  const handleClick = (record: ProductDataType) => {
    setEditInfo(record);
    showModal();
  };

  const [deleteProduct, { isError, isSuccess, isLoading }] =
    useDeleteProductMutation();
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success("Product Deleted Successfully");
    } else if (isError) {
      setColumnIndex(null);
      message.error("Something went wrong");
    }
  }, [isSuccess, isError]);
  useEffect(() => {
    isError && toasterNotification("error", "Something went wrong");
  }, [isError]);
  const handleDelete = async (values: ProductDataType, index: number) => {
    setColumnIndex(index);
    await deleteProduct({ ...values, deleted_by: user?.user_id });
  };

  return [
    {
      title: "SL.",
      dataIndex: "key",
      key: "serial",
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Category",
      dataIndex: "category_title",
      key: "category",
    },

    {
      title: "Create Date",
      key: "product_create_date",

      render: (_, record) => {
        return `${dayjs(record.product_create_date).format("DD MMM YYYY")}`;
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "product_status",

      render: (_, { product_status }) => (
        <Tag
          color={product_status === 0 ? "red" : "green"}
          key={product_status}
        >
          {product_status === 0 ? "Inactive" : "Active"}
        </Tag>
      ),
    },

    {
      title: "Action",
      key: "action",

      render: (_, record, index) => {
        return (
          <Space size="small">
            {permission?.[crud.update] && record.agency_id && (
              <Button
                size="small"
                type="primary"
                onClick={() => handleClick(record)}
              >
                Edit
              </Button>
            )}
            {permission?.[crud.delete] && record.agency_id && (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record, index)}
              >
                <Button
                  danger
                  size="small"
                  type="primary"
                  loading={columnIndex === index && true}
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];
};

export const getCategoryTitle = (
  productsState: IProductsState,
  categoryId: Number
) => {
  const getCategory = productsState.productCategories.find(
    (item) => item.category_id === categoryId
  );

  return getCategory?.category_title;
};
