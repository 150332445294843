import { Col, Form, Row, message } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { selectUser } from "../../../auth/states/userSlice";
import { IMoneyReceiptPost } from "../../../common/types/commonInterfaces";
import { FormatDateV1 } from "../../../common/utils/common.utils";
import { FormButton } from "../../../components/common/FormItem/FormItems";
import InvoiceHeaderForm from "../../../components/common/Invoice/InvoiceHeaderForm";
import InvoiceMoneyReceipt, {
  InvoiceDisPrevRadioButton,
} from "../../../components/common/Invoice/InvoiceMoneyReceipt";
import InvoiceVisaSubTotalForm from "../../../components/common/Invoice/InvoiceSubTotal";
import InvoiceBillingInfo from "../../../components/common/Invoice/billingInfo/InvoiceBillingInfo";
import BreadCrumb from "../../../components/common/breadCrumb/BreadCrumb";
import LoadingIndicator from "../../../components/common/spinner/LoadingIndicator";
import { useCheckCreditLimitMutation } from "../../Client/Client/api/endpoints/clientEndpoints";
import { useGetInvoiceNoQuery } from "../../Invoice(Visa)/Api/Endpoints/invoiceVisaEndpoints";
import PassportInfo from "../../Invoice(Visa)/Components/PassportInfo";
import { usePostInvoiceOtherMutation } from "../Api/invoiceOtherEndpoints";
import HotelInformation from "../Components/HotelInformation";
import TicketInformation from "../Components/TicketInformation";
import TransportInformation from "../Components/TransportInformation";
import {
  IInvoiceOtherFormattedValueType,
  IOtherInvoiceFormValue,
} from "../Type/Invoce.other.interface";

const AddInvoiceOtherPackage = () => {
  const [form] = Form.useForm();
  const [updateAndPrev, setUpdateAndPrev] = useState<boolean>(false);

  const {
    data: getInvoiceNo,
    isLoading,
    isFetching,
  } = useGetInvoiceNoQuery("IO");

  // ============= set total billing ===============
  useEffect(() => {
    form.setFieldsValue({
      invoice_no: getInvoiceNo?.data,
      invoice_show_prev_due: 0,
      invoice_show_discount: 1,
    });
  }, [isFetching]);

  useEffect(() => {
    form.setFieldsValue({
      invoice_sales_date: dayjs(),
    });
  }, []);

  const t = useWatch("invoice_sales_date", form);
  console.log(dayjs(t).format("DD-MM-YYYY"));

  const [
    postInvoiceOther,
    { isError, isSuccess, isLoading: postLoading, error },
  ] = usePostInvoiceOtherMutation();

  const [checkCreditLimit, { isLoading: limitIsLoading }] =
    useCheckCreditLimitMutation();

  // ============== form submit handle
  const user = useSelector(selectUser);
  const onFinish = async (values: IOtherInvoiceFormValue) => {
    const money_receipt: IMoneyReceiptPost = {
      receipt_total_amount: values.receipt_total_amount,
      receipt_payment_type: values.receipt_payment_type,
      account_id: values.account_id,
      charge_amount: values.charge_amount,
      cheque_bank_name: values.cheque_bank_name,
      cheque_number: values.cheque_number,
      receipt_money_receipt_no: values.receipt_money_receipt_no,
      receipt_note: values.receipt_note,
      trans_no: values.trans_no,
      receipt_payment_date: FormatDateV1(values.receipt_payment_date),
    };

    const billing_information = values.billing_information.map((item) => {
      return {
        billing_subtotal: item.billing_subtotal,
        billing_profit: item.billing_profit,
        billing_product_id: item.billing_product_id,
        pax_name: item.pax_name || undefined,
        billing_quantity: item.billing_quantity,
        billing_unit_price: item.billing_unit_price,
        billing_cost_price: item.billing_cost_price,
        billing_comvendor: item.billing_comvendor,
        billing_total_sales: item.billing_subtotal,
        billing_description: item?.billing_description,
      };
    });

    const passport_informations = values.passport_information.map((item) => {
      return {
        ...item,
        passport_date_of_birth: FormatDateV1(item.passport_date_of_birth),
        passport_date_of_issue: FormatDateV1(item.passport_date_of_issue),
        passport_date_of_expire: FormatDateV1(item.passport_date_of_expire),
      };
    });

    const passport_information =
      Object.keys(
        values.passport_information.length && values.passport_information[0]
      ).length !== 0
        ? passport_informations
        : undefined;

    const ticketInfos = values.ticketInfo.map((item) => {
      return {
        ...item,
        ticket_journey_date: FormatDateV1(item.ticket_journey_date),
        ticket_return_date: FormatDateV1(item.ticket_return_date),
        ticket_route: item?.ticket_route ? item.ticket_route : undefined,
      };
    });

    const ticketInfo =
      Object.keys(values.ticketInfo.length && values.ticketInfo[0]).length !== 0
        ? ticketInfos
        : undefined;

    const hotel_informations = values.hotel_information.map((item) => {
      return {
        ...item,
        hotel_check_out_date: FormatDateV1(item.hotel_check_out_date),
        hotel_check_in_date: FormatDateV1(item.hotel_check_in_date),
      };
    });
    const hotel_information =
      Object.keys(
        values.hotel_information.length && values.hotel_information[0]
      ).length !== 0
        ? hotel_informations
        : undefined;

    const transport_informations = values.transport_information.map((item) => {
      return {
        ...item,
        transport_pickup_time:
          item.transport_pickup_time &&
          dayjs(item.transport_pickup_time).format(),
        transport_dropoff_time:
          item.transport_dropoff_time &&
          dayjs(item.transport_dropoff_time).format(),
      };
    });
    const transport_information =
      Object.keys(
        values.transport_information.length && values.transport_information[0]
      ).length !== 0
        ? transport_informations
        : undefined;

    const body: IInvoiceOtherFormattedValueType = {
      ...values,
      invoice_due_date: FormatDateV1(values.invoice_due_date),
      invoice_sales_date: FormatDateV1(values.invoice_sales_date),
      passport_information: passport_information,
      ticketInfo: ticketInfo,
      hotel_information: hotel_information,
      transport_information: transport_information,
      invoice_created_by: user?.user_id as number,
      money_receipt,
    };

    body.billing_information = billing_information;
    checkCreditLimit({
      amount: body.invoice_net_total,
      combClient: body.invoice_combclient_id,
    }).then(async (res: any) => {
      if (res?.data?.data === 1) {
        await postInvoiceOther(body).then((res: any) => {
          if (res?.data?.data?.invoice_id)
            updateAndPrev
              ? navigate(
                  `/invoice-other-package/details/${res.data.data.invoice_id}`,
                  {
                    state: location.pathname,
                  }
                )
              : navigate("/invoice-other-package");
        });
      } else {
        message.error(res?.data?.data);
      }
    });
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      message.success("Invoice other added successfully!");
    }
    if (isError) {
      message.error("There was an error");
    }
  }, [postLoading]);

  const invoice_net_total = useWatch(["invoice_net_total"], form);

  return (
    <ClientsStyle>
      <BreadCrumb arrOfOption={["Invoice (Other Package)"]} />
      {(postLoading || isLoading) && <LoadingIndicator />}
      <Form
        onFinish={onFinish}
        onFinishFailed={() => message.error("Submit failed!")}
        layout="vertical"
        form={form}
      >
        <InvoiceHeaderForm
          form={form}
          invoice_net_totalFieldName={["invoice_net_total"]}
          invoice_agent_com_amountFieldName={["invoice_agent_com_amount"]}
        />
        {/* <PassportInformation form={form} /> */}
        <PassportInfo form={form} />
        <TicketInformation form={form} />
        <HotelInformation form={form} />
        <TransportInformation form={form} />
        {/* conditionalCostVendor == When Vendor is selected then required the Cost Price and vice versa */}
        <InvoiceBillingInfo form={form} conditionalCostVendor />
        <InvoiceVisaSubTotalForm form={form} />

        <InvoiceMoneyReceipt
          form={form}
          netTotal={Number(invoice_net_total || 0)}
        />

        <InvoiceDisPrevRadioButton form={form} style={{ marginTop: -30 }} />

        {/* @AIR TICKET EDIT BUTTON */}
        <Row gutter={[10, 10]}>
          <Col>
            <FormButton
              loading={!updateAndPrev && (isLoading || limitIsLoading)}
              label="CREATE"
              disabled={postLoading || isLoading || limitIsLoading}
              onClick={() => {
                setUpdateAndPrev(false);
              }}
            />
          </Col>
          <Col>
            <FormButton
              loading={updateAndPrev && (isLoading || limitIsLoading)}
              disabled={postLoading || isLoading || limitIsLoading}
              label="CREATE & PREVIEW"
              onClick={() => {
                setUpdateAndPrev(true);
              }}
            />
          </Col>
        </Row>
      </Form>
    </ClientsStyle>
  );
};

export default AddInvoiceOtherPackage;

const ClientsStyle = styled.div`
  .body_left {
    width: 100%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .body_right {
    width: 100%;
    margin-left: auto;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .commonBox {
    margin-bottom: 1rem;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 0.4rem;
    }
  }
`;
