import Table, { ColumnsType } from "antd/es/table";
import React, { useState } from "react";
import { IMoneyReceiptDetails } from "../../Types/MoneyReceiptTypes";
import dayjs from "dayjs";
import { useGetMoneyReceiptDetailsQuery } from "../../api/endpoints/moneyReceiptEndpoints";
import FormHeaderTitle from "../../../Invoice(Visa)/Components/FormHeaderTitle";

type Props = {
  id: string | undefined;
  cashiercomponentRef: React.RefObject<HTMLDivElement>;
};
const MoneyReceiptDetails = (props: Props) => {
  const id = props.id;
  const { cashiercomponentRef } = props;

  const { data } = useGetMoneyReceiptDetailsQuery(String(id));

  const moneyReceiptCostColumn: ColumnsType<IMoneyReceiptDetails> = [
    {
      title: "SL",
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: "Payment Date",
      key: "invclientpayment_date",
      dataIndex: "invclientpayment_date",
      render: (data) => (
        <>{dayjs(data.invclientpayment_date).format("DD-MM-YYYY")}</>
      ),
    },
    {
      title: "Invoice No",
      key: "invoice_no",
      dataIndex: "invoice_no",
    },
    {
      title: "Sales Date",
      key: "invoice_sales_date",
      dataIndex: "invoice_sales_date",
      render: (data) => (
        <>{dayjs(data.invoice_sales_date).format("DD-MM-YYYY")}</>
      ),
    },
    {
      title: "Payment Amount",
      key: "invclientpayment_amount",
      dataIndex: "invclientpayment_amount",
    },
    {
      title: "Invoice Amount",
      key: "invoice_net_total",
      dataIndex: "invoice_net_total",
    },
  ];

  return (
    <div
      ref={cashiercomponentRef}
      className="my-20"
      style={{ backgroundColor: "#FFFFFF", padding: "20px", minWidth: "100vh" }}
    >
      <FormHeaderTitle title="RECEIPT DETAILS" />
      <Table
        size="small"
        bordered
        rowKey={(e) => e.invclientpayment_amount}
        className="invoiceBillingTable"
        rowClassName={"invoiceBillingTd"}
        dataSource={data?.data}
        columns={moneyReceiptCostColumn}
        pagination={false}
        style={{ marginBottom: "20px" }}
      />
    </div>
  );
};

export default MoneyReceiptDetails;
