import { Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import {
  IAirTicketInfo,
  IFlights,
  IPaxDetails,
} from "../../../common/types/commonInterfaces";
import {
  Fixed2,
  FormatDate,
  getTableColumnsWithoutNull,
  isNotEmpty,
} from "../../../common/utils/common.utils";
import { HeightRefsType } from "../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice";
import getInvoiceViewPageController from "../utils/ts/getInvoiceViewPageController";

type Props = {
  flights?: IFlights[];
  paxDetails?: IPaxDetails[];
  airticketDetails?: IAirTicketInfo[];
  heightRefs: HeightRefsType;
  invoice_category_id?: number | undefined;
};
export const PAX_TYPES = {
  1: "Adult",
  2: "Child",
  3: "Infant",
};

function getDiscountInPercentage({
  airticket_base_fare,
  airticket_discount_total,
}: {
  airticket_base_fare: number;
  airticket_discount_total: number;
}) {
  const discountPrice = (airticket_base_fare / 100) * airticket_discount_total;
  return discountPrice;
}

const AirTicketFlightAndPaxView = ({
  flights,
  paxDetails,
  airticketDetails,
  heightRefs,
  invoice_category_id,
}: Props) => {
  // AIR TICKET DISCOUNT VALUE CALCULATE
  const calculateDiscount = (record: any) => {
    let value = record?.airticket_discount_total || 0;

    if (record?.airticket_discount_type === "percentClientTotal") {
      value = getDiscountInPercentage({
        airticket_base_fare: Fixed2(record?.airticket_gross_fare || 0),
        airticket_discount_total: Fixed2(record?.airticket_discount_total || 0),
      });
    } else if (record?.airticket_discount_type === "percentBaseFareTotal") {
      value = getDiscountInPercentage({
        airticket_base_fare: Fixed2(record?.airticket_base_fare || 0),
        airticket_discount_total: Fixed2(record?.airticket_discount_total || 0),
      });
    }

    return value;
  };

  const flightsColumn: ColumnsType<IFlights> = [
    {
      title: <span className="table-column-title"> Sl.</span>,
      align: "center",
      render(value, record, index) {
        return <>{index + 1}</>;
      },
    },
    {
      title: <span className="table-column-title"> From</span>,
      align: "left",
      dataIndex: "flight_from",
      key: "flight_from",
    },
    {
      title: <span className="table-column-title"> To</span>,
      align: "left",
      dataIndex: "flight_to",
      key: "flight_to",
    },
    {
      title: <span className="table-column-title"> Airline</span>,
      align: "left",
      dataIndex: "airline_name",
      key: "airline_name",
    },
    {
      title: <span className="table-column-title"> Departure Time</span>,
      align: "right",
      dataIndex: "fltdetails_departure_time",
      key: "fltdetails_departure_time",
    },
    {
      title: <span className="table-column-title"> Arrival Time</span>,
      dataIndex: "fltdetails_arrival_time",
      key: "fltdetails_arrival_time",
      align: "right",
    },
  ];

  const filteredFlightsColumns = getTableColumnsWithoutNull(
    flights,
    flightsColumn
  );

  const billingsColumn: ColumnsType<any> = [
    {
      title: <span className="table-column-title">Sl.</span>,
      render(value, record, index) {
        return <>{index + 1}</>;
      },
      align: "center",
    },

    {
      title: <span className="table-column-title">Ticket No</span>,
      dataIndex: "airticket_ticket_no",
      key: "airticket_ticket_no",
      align: "left",
      render: (text: string) => <span className="table-column">{text}</span>,
    },

    {
      title: <span className="table-column-title">PNR</span>,
      dataIndex: "airticket_pnr",
      key: "airticket_pnr",
      align: "left",
      render: (text: string) => <span className="table-column">{text} </span>,
    },
    {
      title: <span className="table-column-title"> Class</span>,
      dataIndex: "airticket_classes",
      key: "airticket_classes",
      align: "center",
      render: (text: string) => <span className="table-column">{text} </span>,
    },
    {
      title: <span className="table-column-title"> Class Type</span>,
      dataIndex: "airticket_ticket_type",
      key: "airticket_ticket_type",
      align: "center",
      render: (text: string) => <span className="table-column">{text} </span>,
    },
    {
      title: <span className="table-column-title">Route</span>,
      dataIndex: "airticket_routes",
      key: "airticket_routes",
      align: "left",
    },

    {
      title: <span className="table-column-title"> Journey Date </span>,
      key: "airticket_journey_date",
      render: (_, data) => (
        <span className="table-column">
          {FormatDate(data.airticket_journey_date)}
        </span>
      ),
    },
    {
      title: <span className="table-column-title"> Return Date </span>,
      key: "airticket_return_date",
      render: (_, data) => (
        <span className="table-column">
          {FormatDate(data.airticket_return_date)}
        </span>
      ),
    },
    {
      title: <span className="table-column-title"> Unit Price </span>,
      dataIndex: "airticket_gross_fare",
      key: "airticket_gross_fare",
      align: "right",
      render: (curr) => Fixed2(curr),
    },

    {
      title: <span className="table-column-title">Extra Fee</span>,
      dataIndex: "airticket_extra_fee",
      key: "airticket_extra_fee",
      align: "right",
      render: (curr) => Fixed2(curr),
    },

    {
      title: <span className="table-column-title"> Discount </span>,
      dataIndex: "airticket_discount_total",
      key: "airticket_discount_total",
      align: "right",

      render: (text: string, record) => {
        return (
          <span className="table-column">{calculateDiscount(record)}</span>
        );
      },
    },

    {
      title: <span className="table-column-title"> Total </span>,
      dataIndex: "airticket_client_price",
      key: "airticket_client_price",
      align: "right",
      render: (text: string, record) => (
        <span className="table-column">{Fixed2(text)}</span>
      ),
    },
  ];

  if (invoice_category_id === 2) billingsColumn.splice(9, 1);

  const filteredBillingColumns = getTableColumnsWithoutNull(
    airticketDetails,
    billingsColumn
  );

  const paxDetailsColumn: ColumnsType<any> = [
    {
      title: <span className="table-column-title"> Sl. </span>,
      align: "center",
      render(value, record, index) {
        return <>{index + 1}</>;
      },
    },
    {
      title: <span className="table-column-title">Pax Name</span>,
      dataIndex: "passport_name",
      key: "passport_name",
    },
    {
      title: <span className="table-column-title">Pax Type</span>,
      dataIndex: "passport_person_type",
      key: "passport_person_type",
      render(value, record, index) {
        return (
          <>
            {/* value some time get text and some time get id */}
            {typeof value === "number" || Fixed2(value)
              ? PAX_TYPES[value as 1 | 2 | 3]
              : value}
          </>
        );
      },
    },

    {
      title: <span className="table-column-title"> Passport No </span>,
      dataIndex: "passport_passport_no",
      key: "passport_passport_no",
    },
    {
      title: <span className="table-column-title"> NID</span>,
      dataIndex: "passport_nid_no",
      key: "passport_nid_no",
      align: "center",
    },
    {
      title: <span className="table-column-title">Mobile</span>,
      dataIndex: "passport_mobile_no",
      key: "passport_mobile_no",
      render: (text: string) => <span className="table-column">{text}</span>,
    },
    {
      title: <span className="table-column-title"> Issue Date </span>,
      key: "passport_date_of_issue",
      dataIndex: "passport_date_of_issue",
      render: (_, data) =>
        data.passport_date_of_issue
          ? dayjs(data.passport_date_of_issue).format("D-MM-YYYY")
          : "",
    },
    {
      title: <span className="table-column-title"> Expire Date </span>,
      key: "passport_date_of_expire",
      dataIndex: "passport_date_of_expire",
      render: (_, data) =>
        data.passport_date_of_expire
          ? dayjs(data.passport_date_of_expire).format("D-MM-YYYY")
          : "",
    },
  ];

  const filteredPaxDetailsColumns = getTableColumnsWithoutNull(
    paxDetails,
    paxDetailsColumn
  );

  // @HANDLE PRINT DOUBLE PAGE
  // states, variables and references
  const filight_ref = useRef<HTMLDivElement>(null);
  const pax_ref = useRef<HTMLDivElement>(null);
  const billing_ref = useRef<HTMLDivElement>(null);

  const [pageController, setPageController] = useState<
    {
      identifier: string;
      marginTop: number;
      bottomIdentifier: string;
    }[]
  >();

  useEffect(() => {
    const filight_ref_height = Fixed2(filight_ref.current?.clientHeight) / 96; // value in inch
    const pax_ref_height = Fixed2(pax_ref.current?.clientHeight) / 96; // value in inch
    const billing_ref_height = Fixed2(billing_ref.current?.clientHeight) / 96; // value in inch

    const header_height =
      Fixed2(heightRefs.header_ref.current?.clientHeight) / 96; // value in inch
    const signiture_height =
      Fixed2(heightRefs.signiture_ref.current?.clientHeight) / 96;
    console.log(signiture_height);

    const subtotal_height =
      Fixed2(heightRefs.subtotal_ref.current?.clientHeight) / 96; // value in inch

    const pageControl: any = getInvoiceViewPageController({
      topHeight: header_height,
      footerHeight: subtotal_height + signiture_height,
      pageHeight: 11.7,
      items: [
        {
          height: filight_ref_height,
          identifier: "flight",
        },
        {
          height: pax_ref_height,
          identifier: "pax_details",
        },
        {
          height: billing_ref_height,
          identifier: "billing",
        },
        {
          height: subtotal_height,
          identifier: "footer",
        },
      ],
    });

    setPageController(pageControl);
  }, [flights, paxDetails, airticketDetails]);

  return (
    <div className="mt-20">
      <div ref={filight_ref}>
        {isNotEmpty(flights) && (
          <>
            <Typography.Title level={5} style={{ marginBottom: "5px" }}>
              FLIGHT/ROUTE DETAILS
            </Typography.Title>
            {
              <Table
                size="small"
                bordered
                rowKey={(e) => e.fltdetails_arrival_time}
                style={{ marginBottom: "2rem" }}
                className="invoiceBillingTable"
                rowClassName={"invoiceBillingTd"}
                dataSource={flights}
                columns={filteredFlightsColumns}
                pagination={false}
              />
            }
          </>
        )}
      </div>

      <div
        ref={pax_ref}
        style={{
          marginTop:
            pageController && pageController[1].identifier === "pax_details"
              ? `${pageController[1].marginTop}in`
              : 0,
          // Add border top for identify page end
          borderTop:
            pageController &&
            pageController[1].identifier === "pax_details" &&
            pageController[1].marginTop
              ? "4px dashed lightgray"
              : "",
          paddingTop:
            pageController &&
            pageController[1].identifier === "pax_details" &&
            pageController[1].marginTop
              ? "0.5in"
              : "",
        }}
      >
        {paxDetails?.length ? (
          <>
            <Typography.Title level={5} style={{ marginBottom: "5px" }}>
              PAX DETAILS
            </Typography.Title>
            {
              <Table
                size="small"
                rowKey={(e) => e.airticket_ticket_no}
                style={{ marginBottom: "2rem" }}
                className="invoiceBillingTable"
                rowClassName={"invoiceBillingTd"}
                dataSource={paxDetails}
                columns={filteredPaxDetailsColumns}
                pagination={false}
                bordered
              />
            }
          </>
        ) : (
          <></>
        )}
      </div>

      <div
        ref={billing_ref}
        style={{
          // billing margin control
          marginTop:
            pageController && pageController[2].identifier === "billing"
              ? `${pageController[2].marginTop}in`
              : 0,
          borderTop:
            pageController &&
            pageController[2].identifier === "billing" &&
            pageController[2].marginTop
              ? "4px dashed lightgray"
              : "",
          paddingTop:
            pageController &&
            pageController[2].identifier === "billing" &&
            pageController[2].marginTop
              ? "0.5in"
              : "",
        }}
      >
        {airticketDetails?.length && (
          <>
            <Typography.Title level={5} style={{ marginBottom: "5px" }}>
              BILLING INFO
            </Typography.Title>
            <Table
              size="small"
              bordered
              rowKey={(e) => e.airticket_ticket_no}
              className="invoiceBillingTable"
              rowClassName={"invoiceBillingTd"}
              dataSource={airticketDetails}
              columns={filteredBillingColumns}
              pagination={false}
            />
          </>
        )}
      </div>
      {/* THIS IS CREATE ONLY FOR CONTROL FOOTER */}
      <div
        style={{
          marginTop:
            pageController && pageController[3].identifier === "footer"
              ? `${pageController[3].marginTop}rem`
              : 0,
          borderTop:
            pageController &&
            pageController[3].identifier === "footer" &&
            pageController[3].marginTop
              ? "1px dashed gray"
              : "",
          paddingTop:
            pageController &&
            pageController[3].identifier === "footer" &&
            pageController[3].marginTop
              ? "0.5in"
              : "",
        }}
      ></div>
    </div>
  );
};

export default AirTicketFlightAndPaxView;
