import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import { HTTPResponse } from "../../../common/types/commonTypes";
import { IVendorTicketDueList } from "../types/vendor.interfaces";
import PaymentVendorTicketListSubComponent from "./PaymentVendorTicketListSubComponent";

type Props = {
  form: FormInstance<any>;
  vendorTicketData?: HTTPResponse<IVendorTicketDueList[]>;
};

export default function PaymentVendorTicketList({
  form,
  vendorTicketData,
}: Props) {
  console.log(vendorTicketData);
  return (
    <Row style={{ display: "flex" }} justify={"start"}>
      <Divider orientation="left">Select ticket:</Divider>

      <Form.List name="tickets" initialValue={[{}]}>
        {(fields, { add, remove }) =>
          fields.map((field, index) => {
            return (
              <Row key={index} align="bottom">
                <PaymentVendorTicketListSubComponent
                  form={form}
                  index={index}
                  key={index}
                  vendorTicketData={vendorTicketData}
                />

                <Col xs={3} sm={3} md={3} lg={3}>
                  <Form.Item>
                    {index === 0 ? (
                      <Button type="primary" onClick={() => add()}>
                        <PlusOutlined />
                      </Button>
                    ) : (
                      <>
                        <Divider />
                        <Button
                          type="primary"
                          danger
                          //   disabled={
                          //     disableEdit
                          //       ? disableRemoveVendorIndex
                          //         ? disableRemoveVendorIndex > index
                          //         : false
                          //       : false
                          //   }
                          onClick={() => remove(field.name)}
                        >
                          <MinusOutlined />
                        </Button>
                      </>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            );
          })
        }
      </Form.List>
      <Divider />
    </Row>
  );
}
