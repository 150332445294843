import { Col, Form, Row, Select } from "antd";
import { useWatch } from "antd/es/form/Form";
import { FormInstance } from "antd/lib/form";
import { useEffect } from "react";
import {
  DateInput,
  FormInput,
  FormInputItem,
  NumberInput,
} from "../../../components/common/FormItem/FormItems";
import { SelectVendors } from "../../../components/common/FormItem/SelectCustomFeilds";
import FormHeaderTitle from "../../Invoice(Visa)/Components/FormHeaderTitle";

type IProps = {
  name: number;
  restField: {
    fieldKey?: number;
  };
  form: FormInstance<any>;
};
const NewExistingClientBillingComponent = ({
  name,
  restField,
  form,
}: IProps) => {
  const clientCharge = useWatch(
    ["existingClient", name, "airticket_client_charge"],
    form
  );

  const vendorCharge = useWatch(
    ["existingClient", name, "airticket_vendor_charge"],
    form
  );

  useEffect(() => {
    if (clientCharge || vendorCharge) {
      form.setFieldValue(
        ["existingClient", name, "airticket_profit"],
        clientCharge - vendorCharge
      );
    }
  }, [clientCharge, vendorCharge]);

  return (
    <div className="border p-5" style={{ marginBottom: "20px" }}>
      <Row gutter={[10, 0]}>
        <FormHeaderTitle title={`New Ticket & Billing Info`} />
        {/* Ticket No */}
        <FormInput
          {...restField}
          name={[name, "airticket_ticket_no"]}
          label="Ticket No"
          onChange={(value: string) => {
            console.log("Handle Ticket No:", value);
          }}
          size={4}
          required
          // rules={[
          //   {
          //     validator: async (_, value) => {
          //       if (ticketIsExisting?.data && value) {
          //         return Promise.reject();
          //       } else return Promise.resolve();
          //     },
          //     message: 'Ticket already exist!',
          //   },
          // ]}
        />

        {/* Vendor */}
        <SelectVendors
          {...restField}
          name={[name, "airticket_comvendor"]}
          label="Vendor"
          size={4}
          required
        />

        <Col lg={4}>
          <Form.Item
            name={[name, "airticket_classes"]}
            label={"Air-ticket class"}
            rules={[
              {
                required: true,
                message: `Air-ticket class is required`,
              },
            ]}
          >
            <Select
              placeholder={"Air-ticket class"}
              options={[
                {
                  value: "Economy",
                  label: "Economy",
                },
                {
                  value: "Premium Economy",
                  label: "Premium Economy",
                },
                {
                  value: "Business",
                  label: "Business",
                },
                {
                  value: "First Class",
                  label: "First Class",
                },
              ]}
            />
          </Form.Item>
        </Col>

        {/* issue Date */}
        <DateInput
          {...restField}
          name={[name, "airticket_issue_date"]}
          label="Issue Date"
          size={4}
          required
        />
        {/* Journey Date */}
        <DateInput
          {...restField}
          name={[name, "airticket_journey_date"]}
          label="Journey Date"
          size={4}
          required
        />

        {/* Return Date */}
        <DateInput
          {...restField}
          label="Return Date"
          name={[name, "airticket_return_date"]}
          size={4}
        />

        {/* Client Charge */}
        <NumberInput
          required
          {...restField}
          name={[name, "airticket_client_charge"]}
          label="Client Charge"
          size={4}
          min="0"
          maxChar={10}
          minChar={0}
        />

        {/* Vendor Charge */}
        <NumberInput
          required
          {...restField}
          name={[name, "airticket_vendor_charge"]}
          label="Vendor Charge"
          size={4}
          min="0"
          maxChar={10}
          minChar={0}
        />

        {/* Service Charge */}
        {/* <NumberInput
          {...restField}
          name={[name, 'airticket_service_charge']}
          label='Service Charge'
          size={3}
          min='0'
          maxChar={10}
          minChar={0}
        /> */}

        <FormInputItem
          {...restField}
          name={[name, "passport_name"]}
          label="Passport Name"
          size={4}
        />
        <FormInputItem
          {...restField}
          name={[name, "airticket_pnr"]}
          label="PNR"
          size={4}
        />

        {/* Profit */}
        <NumberInput
          {...restField}
          name={[name, "airticket_profit"]}
          label="Profit"
          size={4}
          readOnly
          min="0"
          maxChar={14}
          minChar={0}
        />
        <NumberInput
          {...restField}
          name={[name, "airticket_extra_fee"]}
          label="Extra Fee"
          size={4}
        />
        {/* hidden file for add data in form */}
        <FormInputItem
          hidden
          {...restField}
          name={[name, "existing_ticket_no"]}
          label="Exist Ticket"
          size={3}
        />
        <FormInputItem
          hidden
          {...restField}
          name={[name, "airticket_existing_invoiceid"]}
          label="airticket invoice id"
          size={3}
        />
        <FormInputItem
          hidden
          {...restField}
          name={[name, "airticket_existing_airticket_id"]}
          label="exist airticket id"
          size={3}
        />
        <FormInputItem
          hidden
          {...restField}
          name={[name, "airticket_airline_id"]}
          label="airticket airline id"
          size={3}
        />
      </Row>
    </div>
  );
};

export default NewExistingClientBillingComponent;
