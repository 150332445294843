import { FileTextOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, Form, Space, Table, Typography } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import {
  Fixed2,
  getTableColumnsWithoutNull,
} from "../../../../common/utils/common.utils";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import ReportsHeader from "../../../../components/common/ReportsHeader";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import FormHeaderTitle from "../../../Invoice(Visa)/Components/FormHeaderTitle";
import {
  useGetDailySalesReportQuery,
  useLazyGetExcelDailySalesReportQuery,
} from "../endpoints/DailySalesReportEndpoints";
import { ISubmitDailySalesReport } from "../types/DailySalesReportTypes";
import { DailySalesReportColumn } from "../utils/DailySalesReportColumn";

const DailySalesReport = () => {
  const [paramData, setParamData] = useState<{
    date_range: string;
    id: number | string;
  }>();
  const [form] = Form.useForm();

  const [fetchExcel] = useLazyGetExcelDailySalesReportQuery();

  const [getData, SetGetData] = useState(true);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });
  const [formDate, setFromDate] = useState<string | Date | dayjs.Dayjs>(
    dayjs()
  );
  const [toDate, setToDate] = useState<string | Date | dayjs.Dayjs>(dayjs());

  const [combClient, setCombClient] = useState("all");
  const [employeeId, setEmployeeId] = useState("all");
  const [productId, setProductId] = useState("all");

  const date_range_value = useWatch(["date_range"], form);
  // Set Default Value
  useEffect(() => {
    form.setFieldValue("date_range", [dayjs(), dayjs()]);
    form.setFieldValue("invoice_combclient_id", "all");
    form.setFieldValue("employee_id", "all");
    form.setFieldValue("product_id", "all");
    setParamData({
      date_range: `?from_date=${dayjs()}&to_date=${dayjs()}`,
      id: "",
    });
  }, []);

  const location = useLocation();

  const { data, isLoading, isFetching } = useGetDailySalesReportQuery(
    {
      ...pagination,
      from_date: new Date(formDate as string).toISOString(),
      to_date: new Date(toDate as string).toISOString(),
      comb_client: combClient,
      employee_id: employeeId,
      product_id: productId,
    },
    { skip: getData }
  );

  let total_sales: number = Fixed2(data?.data?.total_sales);
  let total_cost: number = Fixed2(data?.data?.total_cost);
  let total_service_charge: number = Fixed2(data?.data?.total_service_charge);
  let total_discount: number = Fixed2(data?.data?.total_discount);
  let total_profit: number = Fixed2(data?.data?.total_profit);
  let total_collection: number = Fixed2(data?.data?.total_collection);
  let total_due: number = Fixed2(data?.data?.total_due);

  // This useEffect is working based on Params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const dailyValue = searchParams.get("daily");

    const monthFormDate = searchParams.get("from_date");
    const monthToDate = searchParams.get("to_date");

    const yearFormDate = searchParams.get("y_from_date");
    const yearToDate = searchParams.get("y_to_date");

    if (dailyValue) {
      handleFinish({
        date_range: [dailyValue, dailyValue],
        employee_id: "all",
        invoice_combclient_id: "all",
        product_id: "all",
      });
    }

    if (monthFormDate && monthToDate) {
      form.setFieldValue("date_range", [
        dayjs(monthFormDate),
        dayjs(monthToDate),
      ]);
      handleFinish({
        date_range: [monthFormDate, monthToDate],
        employee_id: "all",
        invoice_combclient_id: "all",
        product_id: "all",
      });
    }

    if (yearFormDate && yearToDate) {
      form.setFieldValue("date_range", [
        dayjs(yearFormDate),
        dayjs(yearToDate),
      ]);
      handleFinish({
        date_range: [yearFormDate, yearToDate],
        employee_id: "all",
        invoice_combclient_id: "all",
        product_id: "all",
      });
    }
  }, [location.search]);

  const handleFinish = (value: ISubmitDailySalesReport) => {
    setCombClient(value.invoice_combclient_id);
    setEmployeeId(value.employee_id);
    setProductId(value.product_id);

    setFromDate(value.date_range[0]);
    setToDate(value.date_range[1]);

    SetGetData(false);

    setPagination((prev) => ({ ...prev, current: 1, pageSize: 20 }));
  };

  useEffect(() => {
    if (formDate && toDate) {
      setParamData({
        date_range: `from_date=${formDate}&to_date=${toDate}`,
        id: "",
      });
    }
  }, [formDate, toDate]);
  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const LastPage = Math.ceil(
    Number(data?.count || 0) / (pagination?.pageSize || 0)
  );

  // DAILY SALES REPORT COLUMNS

  const salesData = data?.data?.data?.map((item, index) => ({
    ...item,
    key: index + 1,
  }));

  const salesColumns = getTableColumnsWithoutNull(
    salesData,
    DailySalesReportColumn({ pagination, paramData })
  );

  const salesReportTableSummary = (_: any) => {
    if (pagination?.current === LastPage)
      return (
        <Table.Summary fixed>
          <Table.Summary.Row style={{ padding: 20, background: "#FFF8E8" }}>
            <Table.Summary.Cell index={0} colSpan={6}>
              <div className="report_total_footer">
                <Typography.Text strong>TOTAL </Typography.Text>
              </div>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={0}>
              <div className="report_total_footer">
                <Typography.Text strong>{total_sales}</Typography.Text>
              </div>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={7}>
              <div className="report_total_footer">
                <Typography.Text strong>{total_cost}</Typography.Text>
              </div>
            </Table.Summary.Cell>

            {total_discount ? (
              <Table.Summary.Cell index={7}>
                <div className="report_total_footer">
                  <Typography.Text strong>{total_discount}</Typography.Text>
                </div>
              </Table.Summary.Cell>
            ) : (
              <></>
            )}

            {total_service_charge ? (
              <Table.Summary.Cell index={7}>
                <div className="report_total_footer">
                  <Typography.Text strong>
                    {total_service_charge}
                  </Typography.Text>
                </div>
              </Table.Summary.Cell>
            ) : (
              <></>
            )}

            <Table.Summary.Cell index={7}>
              <div className="report_total_footer">
                <Typography.Text strong>{total_profit}</Typography.Text>
              </div>
            </Table.Summary.Cell>

            {total_collection ? (
              <Table.Summary.Cell index={7}>
                <div className="report_total_footer">
                  <Typography.Text strong>{total_collection}</Typography.Text>
                </div>
              </Table.Summary.Cell>
            ) : (
              <></>
            )}

            {total_due ? (
              <Table.Summary.Cell index={7}>
                <div className="report_total_footer">
                  <Typography.Text strong>{total_due}</Typography.Text>
                </div>
              </Table.Summary.Cell>
            ) : (
              <></>
            )}
          </Table.Summary.Row>
        </Table.Summary>
      );
  };

  const jsx_com = (
    <>
      <FormHeaderTitle title="Sales Report" />
      {
        <Table
          size="small"
          bordered
          columns={salesColumns}
          dataSource={salesData}
          scroll={{ x: 1500 }}
          loading={{
            spinning: isLoading || isFetching,
            indicator: loadingIndicator,
          }}
          pagination={
            data?.count && data.count > 20
              ? {
                  ...pagination,
                  total: data.count,
                  showSizeChanger: true,
                  pageSizeOptions: ["20", "50", "100", "200", "500"],
                  onChange: handlePaginationChange,
                }
              : false
          }
          summary={salesReportTableSummary}
        />
      }
    </>
  );

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Sales_Report${date_range_value}`,
  });

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: "", title: "Sales Report" }}
        extraInfo={{
          date:
            `${dayjs(formDate).format("DD-MM-YYYY")} to  
              ${dayjs(toDate).format("DD-MM-YYYY")}` || "",
        }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={["Reports", "Sales Report"]} />
      <Space style={{ marginBottom: "1rem" }}>
        <Button type="primary" onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>

        <Button
          type="primary"
          onClick={() => {
            fetchExcel({
              from_date: formDate as string,
              to_date: toDate as string,
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form onFinish={handleFinish} form={form} layout="vertical">
        <ReportsHeader
          size={5}
          showSelectClients
          showSelectEmployee
          showSelectProduct
          date_range
          title="Sales Report"
          required
        />
      </Form>
      {jsx_com}

      {print_content}
    </>
  );
};

export default DailySalesReport;
