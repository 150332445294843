import { Button, Form, Space, Table } from 'antd';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { IUserLoginFromDataType } from '../types/userLoginTypes';
import { useLazyGetUserLoginHistoryQuery } from '../endpoints/userLoginEndpoints';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { UserLoginColumn } from '../utils/UserLoginColumn';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import { useReactToPrint } from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';

const UserLoginHistory = () => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const [date_range_value, setDate_range_value] = useState<string>();
  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
  }, []);
  const [userLoginHistory, { data: userLogin, isLoading }] =
    useLazyGetUserLoginHistoryQuery();

  const onFinish = async (values: IUserLoginFromDataType) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    setDate_range_value(date_range);
    const body: IUserLoginFromDataType = {
      user_id: values.user_id,
      date_range: date_range,
    };
    userLoginHistory({
      user_id: body.user_id,
      date_range: body.date_range,
      currentPage: pagination.current,
      currentSize: pagination.pageSize,
    });
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    if (values.user_id)
      userLoginHistory({
        user_id: values.user_id,
        date_range: date_range,
        currentPage: pagination.current,
        currentSize: pagination.pageSize,
      });
  }, [pagination.pageSize, pagination.current]);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `User_login_report`,
  });
  const jsx_content = (
    <>
      <Table
        size='small'
        bordered
        columns={UserLoginColumn()}
        dataSource={userLogin?.data}
        scroll={{ x: true }}
        pagination={{
          ...pagination,
          total: userLogin?.count,
          showSizeChanger: true,
          pageSizeOptions: ['20', '50', '100', '200', '500'],
          onChange: handlePaginationChange,
        }}
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
      />
    </>
  );
  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_content}
        printRef={componentRef}
        title={{ info_title: '', title: 'User Login History' }}
        extraInfo={{
          date:
            date_range_value
              ?.split('&')
              ?.map((param) => dayjs(param.split('=')[1])?.format('DD-MM-YYYY'))
              ?.join(' to ') || '',
        }}
      />
    </div>
  );
  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'User Login History']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
      </Space>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <ReportsHeader date_range required showSelectUser />
      </Form>

      {/* //----------------//------------// @TABLE HERE //----------------//------------// */}
      <FormHeaderTitle title='User Login History' />
      {jsx_content}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default UserLoginHistory;
