import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Row } from "antd";
import { useWatch } from "antd/es/form/Form";
import { FormInstance } from "antd/lib/form";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import {
  DateInput,
  FormInputItem,
  NumberInput,
} from "../../../components/common/FormItem/FormItems";
import {
  SelectAirline,
  SelectAirport,
  SelectAirportV1FormListString,
  SelectVendors,
} from "../../../components/common/FormItem/SelectCustomFeilds";
import BillingIsDeleted from "../../Invoice(Visa)/Components/BillingIsDeleted";
import { ITicketInfo } from "../Type/Invoce.other.interface";

type Props = {
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
  form: FormInstance<any>;
  name: any;
  index: number;
  ticket_information: ITicketInfo[] | undefined;
  invComClientInfo?: string;
};

function Ticket_Details_List_Info({
  add,
  remove,
  form,
  name,
  index,
  ticket_information,
  invComClientInfo,
}: Props) {
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const [instanceVendorTicket, setInstanceVendorTicket] = useState<number>();
  const { id } = useParams();
  const ticket_no = useWatch(["ticketInfo", name, "ticket_no"], form);
  const ticket_pnr = useWatch(["ticketInfo", name, "ticket_pnr"], form);
  const ticket_route = useWatch(["ticketInfo", name, "ticket_route"], form);
  const ticket_airline_id = useWatch(
    ["ticketInfo", name, "ticket_airline_id"],
    form
  );
  const ticket_reference_no = useWatch(
    ["ticketInfo", name, "ticket_reference_no"],
    form
  );
  const ticket_journey_date = useWatch(
    ["ticketInfo", name, "ticket_journey_date"],
    form
  );
  const ticket_return_date = useWatch(
    ["ticketInfo", name, "ticket_return_date"],
    form
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (
      ticket_no ||
      ticket_pnr ||
      ticket_route ||
      ticket_airline_id ||
      ticket_reference_no ||
      ticket_journey_date ||
      ticket_return_date
    ) {
      setIsRequired(true);
    } else {
      setIsRequired(false);
      timeoutId = setTimeout(() => {
        form.validateFields([
          ["ticketInfo", name, "ticket_no"],
          ["ticketInfo", name, "ticket_pnr"],
          ["ticketInfo", name, "ticket_route"],
          ["ticketInfo", name, "ticket_airline_id"],
          ["ticketInfo", name, "ticket_reference_no"],
          ["ticketInfo", name, "ticket_journey_date"],
          ["ticketInfo", name, "ticket_return_date"],
        ]);
      });
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    ticket_no,
    ticket_pnr,
    ticket_route,
    ticket_airline_id,
    ticket_reference_no,
    ticket_journey_date,
    ticket_return_date,
  ]);
  //-----------------------------
  const [disabled, setDisabled] = useState(false);
  const ticket_is_deleted: 0 | 1 = useWatch(
    ["ticketInfo", index, "ticket_is_deleted"],
    form
  );

  useEffect(() => {
    if (ticket_is_deleted === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [ticket_is_deleted]);

  return (
    <Styling>
      <Row
        style={
          ticket_is_deleted === 1
            ? {
                backgroundColor: "#FFC0CB",
                display: "flex",
                alignItems: "center",
              }
            : { display: "flex", alignItems: "center" }
        }
        gutter={[10, { xs: 8, sm: 16, md: 24, lg: 20 }]}
        className="content_margin"
      >
        <FormInputItem name={[name, "ticket_no"]} label="Ticket No" size={4} />
        <FormInputItem name={[name, "ticket_pnr"]} label="PNR" size={3} />

        <SelectAirport
          label="Route"
          name={[name, "ticket_route"]}
          size={4}
          form={form}
          fullNamePath={["ticketInfo", name, "ticket_route"]}
        />

        <FormInputItem
          label="Reference No"
          name={[name, "ticket_reference_no"]}
          size={3}
        />

        <DateInput
          name={[name, "ticket_journey_date"]}
          label="	Journey Date"
          size={3}
        />
        <DateInput
          name={[name, "ticket_return_date"]}
          label="Return Date"
          size={3}
        />
        <SelectAirline
          name={[name, "ticket_airline_id"]}
          label="Airline"
          size={3}
        />
        <NumberInput
          label="Cost Price"
          name={[name, "ticket_cost_price"]}
          size={4}
          required
          maxChar={14}
          minChar={0}
        />
        <SelectVendors
          label="Vendor"
          size={3}
          name={[name, "ticket_comvendor_id"]}
          required
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value: string) {
                if (getFieldValue("invoice_combclient_id")) {
                  if (getFieldValue("invoice_combclient_id") === value) {
                    return Promise.reject();
                  } else {
                    return Promise.resolve();
                  }
                }
                if (invComClientInfo !== undefined) {
                  if (invComClientInfo === value) {
                    return Promise.reject();
                  } else {
                    return Promise.resolve();
                  }
                }
                return Promise.resolve();
              },
              message: "Client and vendor can't be same!",
            }),
          ]}
          setInstanceVendorSelect={setInstanceVendorTicket}
        />
        {/* TICKET DELETE DETECT BY THIS is_deleted FIELD */}
        <BillingIsDeleted name={[name, "ticket_is_deleted"]} />
        {index === 0 ? (
          <Button
            type="primary"
            style={{ marginTop: "7px" }}
            onClick={() => add()}
          >
            <PlusOutlined />
          </Button>
        ) : (
          <Form.Item label={false}>
            <Button
              type="primary"
              danger
              style={{ marginTop: "30px" }}
              onClick={() => {
                const prev = form.getFieldValue([
                  "ticketInfo",
                  name,
                  "ticket_is_deleted",
                ]);
                form.setFieldValue(
                  ["ticketInfo", name, "ticket_is_deleted"],
                  Number(!prev)
                );
                if (id) {
                  if (
                    ticket_information?.length &&
                    index >= ticket_information.length
                  )
                    remove(name);
                } else {
                  remove(name);
                }
              }}
            >
              <MinusCircleOutlined />
            </Button>
          </Form.Item>
        )}
      </Row>
    </Styling>
  );
}

export default Ticket_Details_List_Info;

const Styling = styled.div`
  @media only screen and (max-width: 667px) {
    .content_margin {
      border: 1px solid lightgray;
      border-radius: 5px;
      margin: 5px 0px;
    }
  }
`;
