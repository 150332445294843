import dayjs from "dayjs";
import React from "react";

export interface IPassportDataType {
  key?: React.Key;
  passport_id: number;
  passport_client_id: number;
  passport_name: string;
  passport_passport_no: number;
  passport_mobile_no: string;
  passport_date_of_birth: string;
  passport_date_of_issue: string;
  passport_date_of_expire: string;
  passport_email: string;
  passport_scan_copy: string;
  passport_upload_photo: string;
  passport_upload_others: string;
  pstatus_name: string;
  client_name: string;
  client_mobile: string;
  passport_create_date: string;
}

export interface IInvoicePassportDataType {
  key?: React.Key;
  invpassport_invoice_id: number;
  invpassport_client_id: number;
  invpassport_airticket_id: number;
  passport_id: number;
  name: string;
  client_name: string;
  invoice_no: string;
  passport_no: string;
  mobile_no: string;
  client_mobile: string;
  date_of_birth: string;
  date_of_issue: string;
  date_of_expire: string;
  invpassport_status: string;
  email: string;
}

export interface ISinglePassport {
  client_id: number;
  client_name: string;
  passport_id: number;
  name: string;
  passport_no: string;
  mobile_no: string;
  date_of_birth: string;
  date_of_issue: string;
  date_of_expire: string;
  email: string;
  nid: string;
  scan_copy: string;
  passport_scan_copy: string;
  passport_upload_photo: string;
  passport_upload_others: string;
  hajiinfo_nid: string;
  haji_info_vouchar_no: number;
  hajiinfo_serial: string;
  passport_note: string;
  hajiinfo_tracking_number: string;
  passport_created_by: number;
  passport_nid_no?: string;
  passport_person_type?: string;
}

export interface IPassport_info {
  passport_no: string;
  name: string;
  email?: string;
  nid: string;
  mobile_no: string;
  passport_person_type: string;
  country_code: string;
  passport_note?: string;
  date_of_birth?: dayjs.Dayjs;
  date_of_issue?: dayjs.Dayjs;
  date_of_expire?: dayjs.Dayjs;
  scan_copy: { originFileObj: File }[];
  upload_photo: { originFileObj: File }[];
  upload_others: { originFileObj: File }[];
}

export interface IPassportAddFormData {
  client_id: number;
  passport_rec_cl_id?: number;
  passport_info: IPassport_info[];
}

export interface IPassportEditFormData extends IPassport_info {
  values: any;
  passport_created_by: number;
}

export const acceptedFileTypes = [
  "image/jpeg",
  "image/jpeg",
  "image/png",
  "application/pdf",
  "image/webp",
];

export interface IPassportStatusDataType {
  status_title: string;
  client_id: number;
  passport_id: number;
  name: string;
  passport_no: string;
  mobile_no: string;
  date_of_birth: string;
  date_of_issue: string;
  date_of_expire: string;
  email: string;
}

export interface IPassportStatusFormDataType {
  passport_id: number;
  status_pstatus_id: string;
  status_create_date: string;
  status_created_by: number;
  status_sms_content: string;
  status_sms_content_client: string;
  passport_holder_number: string;
  client_number: string;
  sms_passport_holder?: boolean;
  sms_client?: boolean;
}

export interface IInvoicePassportStatusFormDataType {
  passport_id: number;
  status_pstatus_id: string;
  status_create_date: string;
  status_created_by: number;
  status_sms_content: string;
  status_sms_content_client: string;
  passport_holder_number: string;
  client_number: string;
}

interface IPassportInfoForm {
  country_code: number;
  scan_copy: {
    file: {
      uid: string;
      lastModified: number;
      lastModifiedDate: string;
      name: string;
      size: number;
      type: string;
      percent: number;
      originFileObj: {
        uid: string;
      };
      status: string;
      response: {
        name: string;
        status: string;
        url: string;
        thumbUrl: string;
      };
      xhr: {};
    };
    fileList: {
      uid: string;
      lastModified: number;
      lastModifiedDate: string;
      name: string;
      size: number;
      type: string;
      percent: number;
      originFileObj: {
        uid: string;
      };
      status: string;
      response: {
        name: string;
        status: string;
        url: string;
        thumbUrl: string;
      };
      xhr: {};
    }[];
  };
  passport_no: string;
  mobile_no: string;
  email: string;
  date_of_birth: string;
  date_of_issue: string;
  date_of_expire: string;
  name: string;
}

interface IPassportFormValue {
  client_id: string;
  passport_info: IPassportInfoForm[];
}
