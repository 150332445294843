import {
  ExceptionOutlined,
  PrinterOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Row, Typography } from 'antd';
import Table from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { SelectClients } from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import {
  useGetAirTicketDetailsQuery,
  useLazyGetAirDetailsExcelQuery,
} from '../Endpoint/AirTicketReport.endpoint';
import { AirTicketDetailsColumn } from '../Utils/AirTicketDetailsColumn';
import { rangePresets } from '../../../../common/utils/common.utils';

type Props = {};

function AirTicketDetails({}: Props) {
  const [paramData, setParamData] = useState<{
    date_range: string;
    id: number | string;
  }>();
  const [form] = Form.useForm();
  const toDate = dayjs().format('YYYY-MM-DD');
  const [date, setDate] = useState({ toDate: toDate, fromDate: toDate });
  const [client, setClient] = useState('');

  const tableRef = useRef(null);

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const { data, isLoading } = useGetAirTicketDetailsQuery({
    ...pagination,
    formDate: date.fromDate,
    todDate: date.toDate,
    client: client,
  });

  useEffect(() => {
    setParamData({
      date_range: `from_date=${date.fromDate}&to_date=${date.toDate}`,
      id: '',
    });
  }, [date]);
  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
    form.setFieldValue('client', 'All');
  }, []);

  const [getExcel] = useLazyGetAirDetailsExcelQuery();

  const downloadExcel = () => {
    getExcel({ formDate: date.fromDate, todDate: date.toDate });
  };

  const onFinish = async (values: any) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    setClient(values.client);
    setDate({ fromDate: setFrom_date, toDate: setTo_date });
  };

  const downloadPdf = useReactToPrint({
    content: () => tableRef.current,
    documentTitle: `AirTicket Details`,
  });

  const jsx_com = (
    <Table
      style={{ width: '100%' }}
      bordered
      size='small'
      className='invoiceBillingTable'
      rowClassName={'invoiceBillingTd'}
      dataSource={data?.data}
      columns={AirTicketDetailsColumn({ pagination, paramData })}
      loading={{ spinning: isLoading, indicator: <LoadingIndicator /> }}
      summary={(pageData) => {
        let Fare = 0;

        pageData.forEach(({ airticket_client_price }) => {
          Fare += Number(airticket_client_price);
        });

        return (
          <Table.Summary.Row style={{ background: '#031c291f', height: 50 }}>
            <Table.Summary.Cell index={0}></Table.Summary.Cell>
            <Table.Summary.Cell index={1}></Table.Summary.Cell>
            <Table.Summary.Cell index={2}></Table.Summary.Cell>
            <Table.Summary.Cell index={3}></Table.Summary.Cell>
            <Table.Summary.Cell index={4}></Table.Summary.Cell>
            <Table.Summary.Cell index={7}></Table.Summary.Cell>
            <Table.Summary.Cell index={5}>Summary</Table.Summary.Cell>
            <Table.Summary.Cell index={6}>{Fare}</Table.Summary.Cell>
            <Table.Summary.Cell index={8}></Table.Summary.Cell>
            <Table.Summary.Cell index={9}></Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
      pagination={
        data?.count !== undefined && data?.count < 20
          ? false
          : {
              ...pagination,
              total: data?.count,
              showSizeChanger: true,
              pageSizeOptions: ['20', '50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }
      }
    />
  );

  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={tableRef}
        title={{
          info_title: '',
          title: 'Air Ticket Details',
        }}
        extraInfo={{
          date:
            paramData?.date_range
              ?.split('&')
              ?.map((param) => dayjs(param.split('=')[1])?.format('DD-MM-YYYY'))
              ?.join(' to ') || '',
        }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'Air Ticket Details']} />

      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row justify={'space-between'}>
          <Col sm={24} md={12} lg={12}>
            <div style={{ display: 'flex', gap: 10 }}>
              <SelectClients
                showAll
                size={6}
                name={'client'}
                offDropDown
                placeholder='Select Client'
              />

              <Form.Item name='date_range'>
                <DatePicker.RangePicker
                  presets={rangePresets}
                  format={'DD-MM-YYYY'}
                  allowClear={false}
                />
              </Form.Item>

              <Button
                type='primary'
                htmlType='submit'
                icon={<SearchOutlined />}
              >
                Search
              </Button>
            </div>
          </Col>

          <Col style={{ display: 'flex', gap: 10 }}>
            <Button type='primary' onClick={downloadPdf}>
              <PrinterOutlined />
              Print
            </Button>
            <Button onClick={downloadExcel} type='primary'>
              <ExceptionOutlined />
              Excel
            </Button>
          </Col>
        </Row>
      </Form>

      <Typography.Text style={{ margin: '5px 0px' }}>
        You have total: {data?.count} rows
      </Typography.Text>

      {jsx_com}

      {print_content}
    </>
  );
}

export default AirTicketDetails;
