import {
  Button,
  Col,
  Descriptions,
  Form,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { IMonthlySummaryFormDataType } from '../types/monthlySummaryTypes';

import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useLazyGetExcelQuery } from '../../ledgers/endpoints/ledgerEndpoints';
import { useLazyGetAllMonthlySummaryQuery } from '../endpoints/monthlySummaryEndpoints';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import { Fixed2 } from '../../../../common/utils/common.utils';
import {
  monthlyClientRefundReportColumn,
  monthlyCollectionReportColumn,
  monthlyExpenseReportColumn,
  monthlySalesReportColumn,
  monthlyVendorPaymentReportColumn,
  monthlyVendorRefundReportColumn,
} from '../utils/monthlySummaryColumn';
const Monthly_Summary = ({
  setValue,
  value,
}: {
  setValue: React.Dispatch<React.SetStateAction<number>>;
  value: number;
}) => {
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    query: string;
  }>({
    query: '',
  });
  //calculation of total amount
  const [fetchMonthlySummary, { data }] = useLazyGetAllMonthlySummaryQuery();

  const onFinish = async (values: IMonthlySummaryFormDataType) => {
    const month = dayjs(values.month).format('YYYY-MM');

    await fetchMonthlySummary({ month: month });
    setExcelQuery({
      query: `?month=${month}`,
    });
  };

  useEffect(() => {
    form.setFieldValue('month', dayjs());
    const month = dayjs().format('YYYY-MM');

    fetchMonthlySummary({ month: month });
    setExcelQuery({
      query: `?month=${month}`,
    });
  }, []);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Monthly_Report`,
  });
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const optionsSummaryReport = [
    { label: 'Daily Summary', value: 1 },
    { label: 'Monthly Summary', value: 2 },
  ];

  // @HANDLE CONTENTS
  const jsx_com = (
    <>
      <Row gutter={[30, 0]}>
        <Col span={24}>
          <div className='mb-10'>
            <FormHeaderTitle title='Summary' />

            <Descriptions size='small' bordered column={1}>
              <DescriptionsItem label='Total Sales'>
                {Fixed2(data?.data?.monthlySalesAmount[0]?.sales_total)}
              </DescriptionsItem>

              <DescriptionsItem label='Total Purchase'>
                {Fixed2(data?.data?.monthlyPurchase[0]?.total_cost)}
              </DescriptionsItem>

              <DescriptionsItem label='Total Account Collection'>
                {Fixed2(
                  data?.data?.monthlyAccountCollection[0]?.account_collection
                )}
              </DescriptionsItem>

              <DescriptionsItem label='Total Vendor Payment'>
                {Fixed2(data?.data?.monthlyVendorPayment.total_payment)}
              </DescriptionsItem>

              <DescriptionsItem label='Total Refund From Client'>
                {Fixed2(
                  data?.data?.monthlyClientRefund[0]?.client_refund_total
                )}
              </DescriptionsItem>

              <DescriptionsItem label='Total Refund From Vendor'>
                {Fixed2(
                  data?.data?.monthlyVendorRefund[0]?.vendor_refund_total
                )}
              </DescriptionsItem>
              <DescriptionsItem label='Total Employee Expense Amount'>
                {Fixed2(data?.data?.employeeExpenseAmount[0]?.salary)}
              </DescriptionsItem>
              <DescriptionsItem label='Total Expense'>
                {Fixed2(data?.data?.monthlyExpenseAmount[0]?.expense_total)}
              </DescriptionsItem>
            </Descriptions>
          </div>
        </Col>
      </Row>

      <FormHeaderTitle title='Monthly Sales Report' />

      <Table
        scroll={{ y: 240 }}
        size='small'
        dataSource={data?.data?.monthlySalesReport}
        columns={monthlySalesReportColumn()}
        rowKey='invoice_id'
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        bordered
        pagination={false}
        summary={(data) => {
          let netTotalSum = 0;
          for (const item of data) {
            netTotalSum += Number(item.invoice_net_total || 0);
          }
          return (
            <Table.Summary.Row
              style={{ background: '#1c529748', fontWeight: 'bold' }}
            >
              <Table.Summary.Cell index={0} colSpan={3} align='right'>
                Summary
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align='right'>
                <Typography
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {Fixed2(netTotalSum)}
                </Typography>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />

      <FormHeaderTitle title='Monthly Expense Report' />

      <Table
        scroll={{ y: 240 }}
        size='small'
        dataSource={data?.data?.monthlyExpenseReport}
        columns={monthlyExpenseReportColumn()}
        rowKey='expense_vouchar_no'
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        bordered
        pagination={false}
        summary={(data) => {
          let expenseAmountSum = 0;
          for (const item of data) {
            expenseAmountSum += Number(item.expdetails_amount || 0);
          }
          return (
            <Table.Summary.Row
              style={{ background: '#1c529748', fontWeight: 'bold' }}
            >
              <Table.Summary.Cell index={0} colSpan={2} align='right'>
                Summary
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align='right'>
                <Typography
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {Fixed2(expenseAmountSum)}
                </Typography>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={0} colSpan={1} align='right' />
            </Table.Summary.Row>
          );
        }}
      />

      <FormHeaderTitle title='Monthly Vendor Payment Report' />

      <Table
        scroll={{ y: 240 }}
        size='small'
        dataSource={data?.data?.monthlyVendorPaymentReport}
        columns={monthlyVendorPaymentReportColumn()}
        rowKey='vouchar_no'
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        bordered
        pagination={false}
        summary={(data) => {
          let payment_amount = 0;
          for (const item of data) {
            payment_amount += Number(item.payment_amount || 0);
          }
          return (
            <Table.Summary.Row
              style={{ background: '#1c529748', fontWeight: 'bold' }}
            >
              <Table.Summary.Cell index={0} colSpan={2} align='right'>
                Summary
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align='right'>
                <Typography
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {Fixed2(payment_amount)}
                </Typography>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />

      <FormHeaderTitle title='Monthly Collection Report' />

      <Table
        scroll={{ y: 240 }}
        size='small'
        dataSource={data?.data?.monthlyCollectionReport}
        columns={monthlyCollectionReportColumn()}
        rowKey='vouchar_no'
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        bordered
        pagination={false}
        summary={(data) => {
          let collectionSum = 0;
          for (const item of data) {
            collectionSum += Number(item.account_collection || 0);
          }
          return (
            <Table.Summary.Row
              style={{ background: '#1c529748', fontWeight: 'bold' }}
            >
              <Table.Summary.Cell index={0} colSpan={2} align='right'>
                Summary
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align='right'>
                <Typography
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {Fixed2(collectionSum)}
                </Typography>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />

      <FormHeaderTitle title='Monthly Client Refund Report' />

      <Table
        scroll={{ y: 240 }}
        size='small'
        dataSource={data?.data?.monthlyClientRefundReport}
        columns={monthlyClientRefundReportColumn()}
        rowKey='vouchar_no'
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        bordered
        pagination={false}
        summary={(data) => {
          let refundTotalSum = 0;
          for (const item of data) {
            refundTotalSum += Number(item.crefund_total_amount || 0);
          }
          return (
            <Table.Summary.Row
              style={{
                background: '#1c529748',
                fontWeight: 'bold',
              }}
            >
              <Table.Summary.Cell index={0} colSpan={2} align='right'>
                Summary
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align='right'>
                <Typography
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {Fixed2(refundTotalSum)}
                </Typography>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />

      <FormHeaderTitle title='Monthly Vendor Refund Report' />

      <Table
        scroll={{ y: 240 }}
        size='small'
        dataSource={data?.data?.monthlyVendorRefundReport}
        columns={monthlyVendorRefundReportColumn()}
        rowKey='vouchar_no'
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        bordered
        pagination={false}
        summary={(data) => {
          let totalRefundCharge = 0;
          let totalReturn = 0;
          for (const item of data) {
            totalRefundCharge += Number(item.vcharge_amount || 0);
            totalReturn += Number(item.vrefund_return_amount || 0);
          }
          return (
            <Table.Summary.Row
              style={{ background: '#1c529748', fontWeight: 'bold' }}
            >
              <Table.Summary.Cell index={0} colSpan={3} align='right'>
                Summary
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align='right'>
                <Typography>{Fixed2(totalRefundCharge)}</Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align='right'>
                <Typography>{Fixed2(totalReturn)}</Typography>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Monthly Summary Report' }}
        extraInfo={{
          date:
            dayjs(excelQuery?.query?.split('=')[1]).format('MMMM YYYY') || '',
        }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'Monthly_Summary Report']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              client_id: '',
              query: excelQuery.query,
              excelApiName: 'monthly-summery',
              excelName: 'monthly-summery',
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
        <Row>
          <Radio.Group
            options={optionsSummaryReport}
            onChange={onChange}
            value={value}
            optionType='button'
            buttonStyle='solid'
          />
        </Row>
      </Space>

      <Form layout='vertical' form={form} onFinish={onFinish}>
        <ReportsHeader required month_select />
      </Form>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      <div>
        <FormHeaderTitle title='Monthly Summary Report' />
        {jsx_com}
      </div>

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default Monthly_Summary;
