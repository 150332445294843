import { Col, Form, message, Row } from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { selectUser } from "../../../auth/states/userSlice";
import { toasterNotification } from "../../../common/utils/ToasterNotification";
import { FormatDateV1 } from "../../../common/utils/common.utils";
import { FormButton } from "../../../components/common/FormItem/FormItems";
import InvoiceHeaderForm from "../../../components/common/Invoice/InvoiceHeaderForm";
import BreadCrumb from "../../../components/common/breadCrumb/BreadCrumb";
import { useCheckCreditLimitMutation } from "../../Client/Client/api/endpoints/clientEndpoints";
import NewBillingInfoForm from "../Components/NewBillingInfoForm";
import { IExistingClients } from "../Components/PreviousTicketInfo";
import { IInvoice_info_Existing } from "../Type/InoviceReissue.interface";
import {
  useGetExistingReIssueInvoiceQuery,
  useGetViewInvoiceReissueQuery,
} from "../api/endpoints/reIssueAirTicketInvoiceEndpoints";
import {
  useEditExistingClientMutation,
  useLazyGetExistingClientsQuery,
} from "../api/endpoints/reIssueExistingAirTicketEndpoints";
import NewExistingClientBillingInfoForm from "../Components/NewExistingClientBillingInfoForm";
import SubtotalAndNetTotalForExistingClient from "../Components/SubtotalAndNetTotalForExistingClient";

type Props = {};

const EditExistingReissue = (props: Props) => {
  const id = Number(useParams().id);
  const [form] = useForm();
  const { data: { data: invoiceData } = {}, isError } =
    useGetViewInvoiceReissueQuery(id);
  const [
    editExistingClient,
    {
      isLoading: postLoading,
      isError: postError,
      isSuccess: postSuccess,
      error,
    },
  ] = useEditExistingClientMutation();
  const existingEditData = {
    //formatting data for reissue edit
    invoice_combclient_id: invoiceData?.invoice_combclient_id,
    invoice_agent_id: invoiceData?.invoice_agent_id,
    invoice_sales_man_id: invoiceData?.invoice_sales_man_id,
    invoice_no: invoiceData?.invoice_no,
    invoice_sales_date: dayjs(invoiceData?.invoice_sales_date),
    invoice_due_date:
      invoiceData?.invoice_due_date && dayjs(invoiceData?.invoice_due_date),
    invoice_sub_total: Number(invoiceData?.invoice_sub_total),
    invoice_service_charge: Number(invoiceData?.invoice_service_charge),
    invoice_net_total: Number(invoiceData?.invoice_net_total),
    invoice_total_vendor_price: Number(invoiceData?.invoice_total_vendor_price),
    invoice_total_profit: Number(invoiceData?.invoice_total_profit),
    invoice_note: invoiceData?.invoice_note,
    existingClient: invoiceData?.airticket_information?.map((item) => {
      //remove null or undefined properties
      const cleanedItem = Object?.fromEntries(
        Object.entries(item).filter(
          ([_, value]) => value !== undefined && value !== null
        )
      );
      return {
        ...cleanedItem,
        existing_ticket_no: item.airticket_ticket_no,
        airticket_existing_invoiceid: item.airticket_invoice_id,
        airticket_existing_airticket_id: item.airticket_id,
        airticket_client_charge: item.airticket_client_price,
        airticket_vendor_charge: item.airticket_purchase_price,
        airticket_comvendor: item?.comb_vendor,
        airticket_issue_date:
          item.airticket_issue_date && dayjs(item.airticket_issue_date),
        airticket_journey_date:
          item.airticket_journey_date && dayjs(item.airticket_journey_date),
        airticket_return_date:
          item.airticket_return_date && dayjs(item.airticket_return_date),
      };
    }),
  };

  useEffect(() => {
    form.setFieldsValue({
      ...existingEditData,
    });
  }, [existingEditData]);

  // ================ existing clients data ==================
  const [existingClientData, { data: exClientsData, isLoading, isSuccess }] =
    useLazyGetExistingClientsQuery();
  const existingClient = exClientsData?.data;
  const existingTicketNo: { id: number; title: string }[] = [];
  if (isSuccess) {
    existingClient?.forEach((item) => {
      const data = {
        id: item?.airticket_id,
        title: item.airticket_ticket_no,
      };
      if (item?.airticket_id) {
        existingTicketNo.push(data);
      }
    });
  }

  const [checkCreditLimit, { isLoading: limiLoading }] =
    useCheckCreditLimitMutation();

  // @Form submit
  const onFinish = (values: any) => {
    const { existingClient, ...all } = values;
    const airticket_client_charge = existingClient?.reduce(
      (total: any, item: any) => total + (item.airticket_client_charge || 0),
      0
    );
    const prevAirticket_client_charge =
      invoiceData?.airticket_information?.reduce(
        (total: any, item: any) => total + (item.airticket_client_price || 0),
        0
      );
    const body = {
      ...all,
      invoice_due_date: FormatDateV1(values?.invoice_due_date),
      invoice_sales_date: FormatDateV1(values?.invoice_sales_date),
      airtickets: values?.existingClient?.map((item: any) => ({
        ...item,
        airticket_issue_date: FormatDateV1(item.airticket_issue_date),
        airticket_journey_date: FormatDateV1(item.airticket_journey_date),
        airticket_return_date: FormatDateV1(item.airticket_return_date),
      })),
    };
    if (id) {
      const prevNetTotal = prevAirticket_client_charge || 0;
      const currentNetTotal = airticket_client_charge;
      if (prevNetTotal >= currentNetTotal) {
        editExistingClient({ body, id });
      } else {
        checkCreditLimit({
          amount: currentNetTotal - prevNetTotal,
          combClient: body?.invoice_combclient_id,
        }).then((res: any) => {
          if (res?.data?.data === 1) {
            editExistingClient({ body, id });
          } else {
            message.error(res?.data?.data);
          }
        });
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error("Submit failed!");
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (postSuccess) {
      toasterNotification(
        "success",
        "Invoice airticket existing client updated successfully!"
      );
      navigate(`/reissueairticket/details/${id}`);
    }
    if (postError) {
      toasterNotification("error", "Thare was an error");
    }
  }, [postLoading]);

  return (
    <ClientsStyle>
      <BreadCrumb arrOfOption={["Invoice (Air Ticket)", "Existing"]} />

      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        layout="vertical"
      >
        <InvoiceHeaderForm
          isEdit
          invoice_agent_com_amountFieldName={["invoice_agent_com_amount"]}
          invoice_net_totalFieldName={["invoice_net_total"]}
          form={form}
        />

        {/* <NewBillingInfoForm form={form} /> */}
        <Row gutter={[10, 0]}>
          <Col sm={24} xs={24} md={18} lg={20}>
            <NewExistingClientBillingInfoForm
              form={form}
              selectClient={existingEditData?.existingClient}
            />
          </Col>
          <Col sm={24} xs={24} md={4} lg={4}>
            <SubtotalAndNetTotalForExistingClient form={form} />
          </Col>
        </Row>
        <FormButton label="Update" />
      </Form>
    </ClientsStyle>
  );
};
export default EditExistingReissue;
const ClientsStyle = styled.div`
  .body_left {
    width: 100%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .body_right {
    width: 100%;
    margin-left: auto;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .commonBox {
    margin-bottom: 1rem;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 0.4rem;
    }
  }
  .topLeftButton {
    margin-right: 0.5rem;
    margin-bottom: auto;
  }
`;
