import { Col, Divider, Form, Select } from "antd";
import { useWatch } from "antd/es/form/Form";
import { FormInstance } from "antd/lib/form";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { HTTPResponse } from "../../../common/types/commonTypes";
import { Fixed2 } from "../../../common/utils/common.utils";
import {
  FormInputItem,
  NumberInput,
} from "../../../components/common/FormItem/FormItems";
import {
  SelectVendors,
  SelectVendorsByInvId,
} from "../../../components/common/FormItem/SelectCustomFeilds";
import {
  IAddVendorPayment,
  IGetVendorByInvNumber,
  ISpecific_Inv_Vendors_Payment,
  ITicketsVendorDue,
  IVendorTicketDueList,
} from "../types/vendor.interfaces";

type Props = {
  index: number;
  vendorTicketData?: HTTPResponse<IVendorTicketDueList[]>;
  form: FormInstance<any>;
  disableRemoveVendorIndex?: number | undefined;
  disableEdit?: any;
};

export default function PaymentVendorTicketListSubComponent({
  index,
  vendorTicketData,
  form,
}: Props) {
  //   const [disabled, setDisabled] = useState(false);

  //   useEffect(() => {
  //     if (
  //       disableEdit &&
  //       disableRemoveVendorIndex &&
  //       disableRemoveVendorIndex > index
  //     ) {
  //       setDisabled(true);
  //     }
  //   }, [disableRemoveVendorIndex, disableEdit]);

  const ticket_no = useWatch(["tickets", index, "ticket_no"], form);
  // console.log({ ticket_no });
  const { vpay_id } = useParams();

  useEffect(() => {
    if (ticket_no) {
      const findData = vendorTicketData?.data?.find(
        (item) => item.airticket_ticket_no === ticket_no
      );
      // console.log({ ticketData: findData });
      //   const editTimeAmount =
      //     (prevData?.specific_inv_vendors &&
      //       Number(
      //         prevData?.specific_inv_vendors[index]?.specific_inv_amount || 0
      //       )) ||
      //     0;
      //   const editTimeAmount = false;

      form.setFieldValue(
        ["tickets", index, "comb_vendor"],
        findData?.comb_vendor
      );
      form.setFieldValue(
        ["tickets", index, "invoice_id"],
        findData?.airticket_invoice_id
      );
      form.setFieldValue(
        ["tickets", index, "airticket_base_fare"],
        Fixed2(findData?.airticket_base_fare) || 0
      );
      form.setFieldValue(
        ["tickets", index, "vendor_payment"],
        Fixed2(findData?.vendor_payment) || 0
      );
      form.setFieldValue(
        ["tickets", index, "due_amount"],
        Fixed2(findData?.due_amount) || 0
      );
    }
  }, [ticket_no, vendorTicketData]);

  const paid = Fixed2(useWatch(["tickets", index, "vendor_payment"], form));
  const due = Fixed2(useWatch(["tickets", index, "due_amount"], form));
  console.log({ paid, due });
  return (
    <>
      {index !== 0 ? <Divider /> : ""}
      {/* <SelectVendorsByInvId
        name={[index, "comb_vendor_specific_invoice"]}
        label="Vendors:"
        size={4}
        mdSize={6}
        smSize={6}
        disabled={disabled}
        required
        manualData={vendorTicketData?.data?.map((item) => ({
          vendor_id: item?.comb_vendor?.split("-")[1],
          vendor_name: item.vendor_name,
          vendor_type: item?.comb_vendor?.split("-")[0] as
            | "vendor"
            | "combined",
        }))}
        rules={[
          ({ getFieldValue }) => ({
            validator: (_, value) => {
              const specific_inv_vendors: ISpecific_Inv_Vendors_Payment[] =
                getFieldValue(["specific_inv_vendors"]);
              const isFound = specific_inv_vendors?.filter(
                (item, indexI) =>
                  indexI !== index &&
                  item?.comb_vendor_specific_invoice === value
              );

              if (isFound.length) {
                return Promise.reject();
              } else {
                return Promise.resolve();
              }
            },
            message: "Vendor can not be same!",
          }),
        ]}
      /> */}
      <Col xs={4} sm={4}>
        <Form.Item
          label="Ticket"
          name={[index, "ticket_no"]}
          rules={[
            ({ getFieldValue }) => ({
              validator: (_, value) => {
                const tickets: ITicketsVendorDue[] = getFieldValue(["tickets"]);
                const isFound = tickets?.filter(
                  (item, indexI) =>
                    indexI !== index && item?.ticket_no === value
                );
                console.log(isFound, tickets);
                if (isFound.length) {
                  return Promise.reject();
                } else {
                  return Promise.resolve();
                }
              },
              message: "Ticket can not be same!",
            }),
          ]}
        >
          <Select
            className="w-full"
            placeholder="Select Ticket"
            showSearch
            dropdownMatchSelectWidth={250}
            optionFilterProp="children"
            filterOption={(
              input: string,
              option?: { label: string; value: string }
            ) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={vendorTicketData?.data?.map((item: any) => ({
              value: item.airticket_ticket_no,
              label: item.airticket_ticket_no,
            }))}
            allowClear
          />
        </Form.Item>
      </Col>

      <FormInputItem
        name={[index, "comb_vendor"]}
        label={`Total: `}
        size={4}
        mdSize={6}
        smSize={6}
        maxChar={10}
        minChar={0}
        hidden
      />
      <FormInputItem
        name={[index, "invoice_id"]}
        label={`Total: `}
        size={4}
        mdSize={6}
        smSize={6}
        maxChar={10}
        minChar={0}
        hidden
      />

      <FormInputItem
        name={[index, "invoice_id"]}
        label={`Total: `}
        size={4}
        mdSize={6}
        smSize={6}
        maxChar={10}
        minChar={0}
        hidden
      />
      <FormInputItem
        name={[index, "airticket_base_fare"]}
        label={`Total: `}
        size={4}
        mdSize={6}
        smSize={6}
        maxChar={10}
        minChar={0}
        readOnly
      />

      <FormInputItem
        name={[index, "vendor_payment"]}
        label={`Paid: `}
        size={4}
        mdSize={6}
        smSize={6}
        maxChar={10}
        minChar={0}
        readOnly
      />

      <FormInputItem
        name={[index, "due_amount"]}
        label="Due:"
        size={4}
        mdSize={6}
        smSize={6}
        maxChar={10}
        minChar={0}
        readOnly
      />

      <NumberInput
        name={[index, "invoice_amount"]}
        label="Amount:"
        size={4}
        mdSize={6}
        smSize={6}
        maxChar={10}
        minChar={0}
        required
        // max={vpay_id ? paid + due : due}
        min="0"
        rules={[
          ({ getFieldValue }) => ({
            validator(rule, value, callback) {
              if (value) {
                if (vpay_id && !(value <= due + paid)) {
                  return Promise.reject();
                }
                if (!vpay_id && value > due) {
                  return Promise.reject();
                }
              }
              return Promise.resolve();
            },
            message: `Amount can't greater than ${vpay_id ? "total" : "due"}!`,
          }),
        ]}
      />
    </>
  );
}
