import { Button, Popconfirm, Space, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import useGetSearchColumnProps from "../../../../common/hooks/SearchData";
import {
  IPermission,
  ISetState,
  crud,
} from "../../../../common/types/commonTypes";
import { useDeleteClientCategoryMutation } from "../endpoints/clientCategoryEndpoints";
import { ClientCategoryDataType } from "../types/clientCategoryTypes";
import { RollbackOutlined } from "@ant-design/icons";

type Props = {
  setEditInfo: ISetState<ClientCategoryDataType | null>;
  showModal: () => void;
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
  isTrash: boolean;
};

export const columns = ({
  pagination,
  setEditInfo,
  showModal,
  permission,
  isTrash,
}: Props): ColumnsType<ClientCategoryDataType> => {
  const searchColumnProps = useGetSearchColumnProps<ClientCategoryDataType>();
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const handleClick = (record: ClientCategoryDataType) => {
    setEditInfo(record);
    showModal();
  };

  const [deleteClientCategory, { isError, isSuccess, isLoading, error }] =
    useDeleteClientCategoryMutation();

  const customErr: any = error;

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success("Client Category Deleted Successfully");
    } else if (isError) {
      setColumnIndex(null);

      message.error(customErr?.data.message);
    }
  }, [isSuccess, isError]);

  const handleDelete = async (
    values: ClientCategoryDataType,
    is_delete: 0 | 1,
    index: number
  ) => {
    setColumnIndex(index);
    await deleteClientCategory({
      body: { ...values },
      is_delete,
    });
  };
  return [
    {
      title: "Sl.",
      dataIndex: "key",
      key: "key",
      width: 50,
    },
    {
      title: "Category Name",
      dataIndex: "category_title",
      key: "name",
      width: 50,

      ...searchColumnProps("category_title"),
    },
    {
      title: "Prefix",
      dataIndex: "category_prefix",
      key: "prefix",
      width: 50,
    },

    {
      title: "Action",
      key: "action",
      width: 30,
      align: "center",

      render: (_, record, index) => (
        <Space size="small">
          {permission?.[crud.update] && record.agency_id ? (
            <Button
              size="small"
              type="primary"
              onClick={() => handleClick(record)}
            >
              Edit
            </Button>
          ) : (
            ""
          )}

          {permission?.[crud.delete] && record.agency_id ? (
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record, 1, index)}
            >
              <Button
                danger
                size="small"
                type="primary"
                loading={columnIndex === index && true}
              >
                Delete
              </Button>
            </Popconfirm>
          ) : (
            ""
          )}
        </Space>
      ),
    },
  ];
};
