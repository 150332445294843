import { api } from "../../../../app/baseQuery";
import { HTTPResponse } from "../../../../common/types/commonTypes";
import { IAccountsLastBalance } from "../../../Accounts/AccountsTypes/AccountsTypes";
import { ACCOUNT_LIST } from "../../../Accounts/Api/Constants/constants";
import { CHEQUE_PAYMENT } from "../../../Cheque_Management/api/Constants/constants";
import {
  IAddVendorPayment,
  IAddVendorPaymentView,
  IGetAllInvoiceType,
  IGetVendorByInvNumber,
  IGetVendorInvoiceDue,
  IVendorPaymentDetails,
  IVendorPaymentList,
  IVendorTicketDueList,
} from "../../types/vendor.interfaces";

export const vendorPaymentEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    /**
     * get all vendors
     *
     */
    getAllVendorPayment: build.query<
      HTTPResponse<IVendorPaymentList[]>,
      { pageSize: number; current: number; search?: string }
    >({
      query: (arg) => ({
        url: `/vendors/vendor-payments?page=${arg.current}&size=${
          arg.pageSize
        }&search=${arg.search || ""}`,
      }),
      providesTags: () => [{ type: "Payments", id: "VENDOR_PAYMENT" }],
    }),

    getAllVendorPaymentReport: build.query<
      HTTPResponse<IVendorPaymentList[]>,
      { pageSize: number; current: number; from_date: string; to_date: string }
    >({
      query: (arg) => ({
        url: `/vendors/vendor-payments?page=${arg.current}&size=${arg.pageSize}&from_date=${arg.from_date}&to_date=${arg.to_date}`,
      }),
      providesTags: () => [{ type: "Payments", id: "VENDOR_PAYMENT" }],
    }),

    getVPayForEdit: build.query<HTTPResponse<any>, number>({
      query: (id) => ({
        url: `/vendors/get-vpay-for-edit/${id}`,
      }),
      providesTags: () => [{ type: "Payments", id: "VENDOR_PAYMENT" }],
    }),

    getVPayForView: build.query<HTTPResponse<IAddVendorPaymentView>, number>({
      query: (id) => ({
        url: `/vendors/view-vendor-payment/${id}`,
      }),
    }),

    postVendorPayment: build.mutation<any, FormData>({
      query: (body) => ({
        url: "/vendors/post-vendor-payments",
        method: "POST",
        body,
      }),
      invalidatesTags: [
        { type: "Payments", id: "VENDOR_PAYMENT" },
        { type: "Cheque_Payment", id: CHEQUE_PAYMENT },
      ],
    }),

    editVendorPayment: build.mutation<
      HTTPResponse<void>,
      { body: FormData; id: number }
    >({
      query: ({ body, id }) => ({
        url: `/vendors/update-vendor-payment/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [{ type: "Payments", id: "VENDOR_PAYMENT" }],
    }),

    deleteVendorPayment: build.mutation<
      HTTPResponse<void>,
      { id: number; updated_by: number }
    >({
      query: (body) => ({
        url: `/vendors/vendor-payment-delete/${body.id}`,
        method: "DELETE",
        body: { updated_by: body.updated_by },
      }),
      invalidatesTags: [{ type: "Payments", id: "VENDOR_PAYMENT" }],
    }),

    // =========== testing=======
    getAccountByType: build.query<
      HTTPResponse<
        {
          account_id: number;
          account_name: string;
          last_balance: number;
        }[]
      >,
      number
    >({
      query: (paymentTypeId) => ({
        url: `/accounts/account-by-type/${paymentTypeId}`,
      }),
      providesTags: [{ type: "Accounts", id: ACCOUNT_LIST }],
    }),

    // get accounts last balance
    getAccountsLastBalance: build.query<
      HTTPResponse<IAccountsLastBalance[]>,
      number
    >({
      query: (peymentMethodId) => ({
        url: `/accounts/accounts-function/balance-status${peymentMethodId}`,
      }),
    }),

    // get accounts last balance
    getOverAllInvoices: build.query<HTTPResponse<IGetAllInvoiceType[]>, void>({
      query: () => ({
        url: `/invoice-air-ticket/all-invoiceno-and-id`,
      }),
    }),

    // get all invoices for vendor payments
    getOverAllInvoicesFor_V_Payment: build.query<
      HTTPResponse<IGetAllInvoiceType[]>,
      void
    >({
      query: () => ({
        url: `/vendors/all-vendor-invoices`,
      }),
    }),

    // get all invoices for vendor payments edit
    getOverAllInvoicesFor_V_Payment_edit: build.query<
      HTTPResponse<IGetAllInvoiceType[]>,
      void
    >({
      query: () => ({
        url: `/vendors/all-vendor-invoices-for-edit`,
      }),
    }),

    // get vendor invoice due
    getVendorInvoiceDue: build.query<
      HTTPResponse<IGetVendorInvoiceDue>,
      number
    >({
      query: (invoice_id) => ({
        url: `/vendors/vendor-invoice-due/${invoice_id}`,
      }),
    }),

    // get vendor invoice due
    getVendorByInvoiceId: build.query<
      HTTPResponse<IGetVendorByInvNumber[]>,
      number
    >({
      query: (invoice_id) => ({
        url: `/vendors/vendors_by_invoice/${invoice_id}`,
      }),
    }),

    getVendorPaymentDetails: build.query<
      HTTPResponse<IVendorPaymentDetails[]>,
      number
    >({
      query: (id) => ({
        url: `/vendors/view-vendor-payment-details/${id}`,
      }),
    }),
    // get vendor ticket due
    getVendorTicketDue: build.query<
      HTTPResponse<IVendorTicketDueList[]>,
      string
    >({
      query: (id) => ({
        url: `/vendors/vendor-ticket-due/${id}`,
      }),
    }),
  }),
});

export const {
  useGetVPayForEditQuery,
  useLazyGetVPayForEditQuery,
  useGetAllVendorPaymentQuery,
  useGetAccountsLastBalanceQuery,
  useDeleteVendorPaymentMutation,
  usePostVendorPaymentMutation,
  useEditVendorPaymentMutation,
  useGetAccountByTypeQuery,
  useLazyGetVPayForViewQuery,
  useGetOverAllInvoicesQuery,
  useGetOverAllInvoicesFor_V_PaymentQuery,
  useGetOverAllInvoicesFor_V_Payment_editQuery,
  useLazyGetVendorInvoiceDueQuery,
  useLazyGetVendorByInvoiceIdQuery,
  useGetVendorPaymentDetailsQuery,
  useGetAllVendorPaymentReportQuery,
  useLazyGetVendorTicketDueQuery,
} = vendorPaymentEndpoints;
