import { Button, Form, Input, InputNumber, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectUser } from "../../../../auth/states/userSlice";
import { selectCommon, setModal } from "../../../../common/slices/commonSlice";
import { ISetState } from "../../../../common/types/commonTypes";
import { Fixed2 } from "../../../../common/utils/common.utils";
import { useSendSMSMutation } from "../../../SMS System/Api/Endpoints/SMSEndpoints";
import { ISMSFormData } from "../../../SMS System/SMSTypes/SMSTypes";
import { ClientDataType } from "../types/clientInterfaces";
import TextArea from "antd/es/input/TextArea";

interface IProps {
  info: ClientDataType;
  setNull: ISetState<ClientDataType | null>;
}
const ClientSmsModal = ({ info, setNull }: IProps) => {
  const org = useAppSelector((state) => state.user?.organization_info);
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const modalSlice = useSelector(selectCommon);
  const [form] = Form.useForm();
  const {
    client_category_id,
    client_id,
    client_name,
    client_lbalance,
    client_mobile,
  } = info;

  useEffect(() => {
    form.setFieldsValue({
      client_name: client_name,
      client_lbalance: -(client_lbalance != undefined && client_lbalance),
      client_mobile,
      message: `Dear ${client_name}, You have a DUE of ${Math.abs(
        Fixed2(client_lbalance)
      )}. Kindly clear your dues. ${org?.org_name}, Contact No: ${
        org?.org_mobile
      }`,
    });
  }, [info]);

  const [sendSMS, { isError, isSuccess }] = useSendSMSMutation();
  const handleOnSubmit = async (values: ISMSFormData) => {
    let correct_phoneNo: string;
    if (client_mobile?.startsWith("88") || client_mobile?.startsWith("+88")) {
      correct_phoneNo = client_mobile.split(/88|\+88/)[1];
    } else {
      correct_phoneNo = client_mobile;
    }

    const body: ISMSFormData[] = [
      {
        client_mobile: `+88${correct_phoneNo}`,
        client_id: `client-${client_id}`,
        text_type: "TEXT",
        client_category_id: 1,
        message: values.message,
        date: dayjs().format("YYYY-MM-DD"),
        created_by: user?.user_id as number,
      },
    ];
    await sendSMS(body);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setModal(false));
      setNull(null);
    }
  }, [isSuccess]);

  return (
    <div>
      <Form layout="vertical" onFinish={handleOnSubmit} form={form}>
        <Form.Item required name="client_name" label="Name:">
          <Input readOnly />
        </Form.Item>
        <Form.Item required name="client_lbalance" label="Due Amount : ">
          <InputNumber readOnly />
        </Form.Item>

        <Form.Item required name="message" label="Message : ">
          <TextArea />
        </Form.Item>
        <Form.Item required name="client_mobile" label="Mobile : ">
          <Input readOnly />
        </Form.Item>

        {!client_mobile && (
          <Typography.Text type="danger">
            Please provide a valid mobile number to send SMS.
          </Typography.Text>
        )}

        <Form.Item style={{ marginTop: "0.5rem" }}>
          <Button type="primary" htmlType="submit" disabled={!client_mobile}>
            {modalSlice.isModal}Send SMS
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ClientSmsModal;
