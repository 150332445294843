import React from "react";
import {
  FormInputItem,
  TextAreaInput,
} from "../../../components/common/FormItem/FormItems";
import { Row } from "antd";
import { FormInstance } from "antd/lib/form";
import { useWatch } from "antd/es/form/Form";

const SubtotalAndNetTotalForExistingClient = ({
  form,
}: {
  form: FormInstance<any>;
}) => {
  const invoice_service_charge = useWatch("invoice_service_charge", form);
  const airticket_subtotal = useWatch("invoice_sub_total", form);
  const existingClientBill = useWatch(["existingClient"]);
  if (existingClientBill && existingClientBill.length) {
    const total_Subtotal = existingClientBill.reduce(
      (acc: number, curr: any) => {
        return acc + (curr.airticket_client_charge || 0); // Safely add airticket_profit
      },
      0
    ); // Initialize accumulator as 0
    form.setFieldsValue({
      invoice_sub_total: total_Subtotal,
    });
    const total_Profit = existingClientBill.reduce((acc: number, curr: any) => {
      return acc + (curr.airticket_profit || 0); // Safely add airticket_profit
    }, 0); // Initialize accumulator as 0
    form.setFieldsValue({
      invoice_total_profit: total_Profit,
    });
    const total_Vendor = existingClientBill.reduce((acc: number, curr: any) => {
      return acc + (curr.airticket_vendor_charge || 0); // Safely add airticket_profit
    }, 0); // Initialize accumulator as 0
    form.setFieldsValue({
      invoice_total_vendor_price: total_Vendor,
    });
  }
  if (invoice_service_charge || airticket_subtotal) {
    form.setFieldsValue({
      invoice_net_total:
        Number(airticket_subtotal) + (Number(invoice_service_charge) || 0),
    });
  }
  return (
    <div className="border p-5">
      <Row>
        <FormInputItem
          name={"invoice_sub_total"}
          label="Subtotal :"
          size={24}
          readOnly
        />
        <FormInputItem
          name={"invoice_service_charge"}
          label="Service Charge :"
          size={24}
        />
        <FormInputItem
          name={"invoice_net_total"}
          label="NetTotal :"
          size={24}
          readOnly
        />
        <FormInputItem
          //   hidden
          name={"invoice_total_profit"}
          label="Total Profit :"
          size={24}
          readOnly
        />
        <FormInputItem
          //   hidden
          name={"invoice_total_vendor_price"}
          label="Total Vendor Price:"
          size={24}
          readOnly
        />
        <TextAreaInput size={24} label="Note" name="invoice_note" />
      </Row>
    </div>
  );
};

export default SubtotalAndNetTotalForExistingClient;
