import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  TableParams,
  handleTableChange,
  rangePresets,
} from '../../../../common/utils/common.utils';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import { SelectClients } from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useLazyGetExcelQuery } from '../../ledgers/endpoints/ledgerEndpoints';

import { useLocation } from 'react-router';
import { IAitReportFormDataTypes } from '../../AitReport/types/aitReportTypes';
import { useLazyGetClientAitReportQuery } from '../endpoints/ClientAitReportEndpoints';
import ClientAitReportColumn from '../utils/ClientAitReportColumn';

const ClientAitReport = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelQuery();
  const [date_range_value, setDate_range_value] = useState<string>();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: '',
    query: '',
  });
  const [fetchClientAitReport, { data, isLoading }] =
    useLazyGetClientAitReportQuery();

  const onFinish = async (values: IAitReportFormDataTypes) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    setDate_range_value(date_range);
    const body = {
      date_range: date_range,
      vendor_id: values.invoice_combvendor_id,
    };
    await fetchClientAitReport({
      client_id: body.vendor_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: body.vendor_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };
  //pagination--start---
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
  }, [data]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 20 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ['20', '50', '100', '200', '500'],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IAitReportFormDataTypes = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      date_range: date_range,
      vendor_id: values.invoice_combvendor_id,
    };

    fetchClientAitReport({
      client_id: values.invoice_combvendor_id,
      query: `${query}&${body.date_range}`,
    });

    setExcelQuery({
      Id: values.invoice_combvendor_id.toString(),
      query: `${query}&${body.date_range}`,
    });
  };

  const toDate = dayjs().format('YYYY-MM-DD');

  useEffect(() => {
    if (searchParams && location.search) {
      const formDate = searchParams.get('from_date');
      const toDate = searchParams.get('to_date');
      form.setFieldValue('date_range', [dayjs(formDate), dayjs(toDate)]);
      form.setFieldValue('invoice_combvendor_id', 'all');
      form.submit();
    } else {
      const date_range = `from_date=${toDate}&to_date=${toDate}`;
      form.setFieldValue('date_range', [dayjs(), dayjs()]);
      form.setFieldValue('invoice_combvendor_id', 'all');
      const body = {
        date_range: date_range,
        vendor_id: 'all',
      };
      fetchClientAitReport({
        client_id: body.vendor_id,
        query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
      });
      setExcelQuery({
        Id: 'all',
        query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
      });
    }
  }, []);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Ait_Report`,
  });

  console.log(data);

  // @HANDLE CONTENTS
  const jsx_com = (
    <ConfigProvider>
      <Table
        size='small'
        bordered
        pagination={count > 20 ? tableParams.pagination : false}
        dataSource={data?.data}
        columns={ClientAitReportColumn(queryData)}
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
        onChange={(args1, args2, args3) =>
          handleTableChange({
            args: {
              pagination: args1,
              filters: args2,
              sorter: args3,
            },
            states: {
              refetch,
              setQueryData,
              setTableParams,
              tableParams,
            },
          })
        }
      />
    </ConfigProvider>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'AIT Report' }}
        extraInfo={{
          date:
            date_range_value
              ?.split('&')
              ?.map((param) => dayjs(param.split('=')[1])?.format('DD-MM-YYYY'))
              ?.join(' to ') || '',
        }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'AIT Report']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: 'client-ait-excel',
              excelName: 'clientAitExcel',
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row justify={'space-between'}>
          <Col>
            <Row gutter={16}>
              <SelectClients
                required
                name='invoice_combvendor_id'
                size={8}
                label='Select Client'
                placeholder='Select Client'
                showAll
                offDropDown
              />
              <Col span={6} xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  label='Date Range'
                  name='date_range'
                  rules={[
                    { required: true, message: ` Date Range is required!` },
                  ]}
                >
                  <DatePicker.RangePicker
                    presets={rangePresets}
                    format={'DD-MM-YYYY'}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col lg={2}>
                <FormButton label='Search' icon textAlign='left' />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <FormHeaderTitle title='Ait Report' />

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default ClientAitReport;
