import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Image,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../../../app/hooks";
import { selectUser } from "../../../../auth/states/userSlice";
import { IPermission } from "../../../../common/types/commonTypes";
import {
  getImageUrl,
  rangePresets,
} from "../../../../common/utils/common.utils";
import { removeSpaceBeforeWord } from "../../../../components/common/Invoice/InvoiceListHeader";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import LoadingIndicator from "../../../../components/common/spinner/LoadingIndicator";
import {
  useDeleteMoneyReturnMutation,
  useGetAllMoneyReceiptsQuery,
} from "../../api/endpoints/moneyReceiptEndpoints";

type Props = { permission?: IPermission };

interface DataType {
  key: React.Key;
  receipt_has_deleted: number;
  trxntype_name: string;
  receipt_vouchar_no: string;
  receipt_payment_date: string;
  acctype_name: string;
  client_name: string;
  receipt_total_amount: number;
  receipt_id: number;
  account_name?: string;
  receipt_money_receipt_no?: string | null;
  cheque_or_bank_name?: string;
  mobile?: string;
  receipt_scan_copy?: string;
}

const ListofMoneyReceipt = ({ permission }: Props) => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useAppSelector(selectUser);
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState("");

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const { data, isLoading, isFetching, refetch } = useGetAllMoneyReceiptsQuery({
    ...pagination,
    from: date[0] || "",
    to: date[1] || "",
    search: search,
  });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 20,
    }));
  }, [date, search]);

  const [deleteMoneyReceipt, { isLoading: deleteLoading, isSuccess, isError }] =
    useDeleteMoneyReturnMutation();

  const handleDelete = async (values: DataType, index: number) => {
    setColumnIndex(index);
    await deleteMoneyReceipt({
      id: values.receipt_id,
      userId: user?.user_id as number,
    });
  };
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isError) {
      setColumnIndex(null);
    }
  }, [isSuccess, isError]);
  const columns: ColumnsType<DataType> = [
    {
      title: "SL.",
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: "Payment Date",
      dataIndex: "receipt_payment_date",
      key: "receipt_payment_date",
      align: "left",
    },

    {
      title: "Vouchar No",
      dataIndex: "receipt_vouchar_no",
      key: "receipt_vouchar_no",
      align: "left",
    },

    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      align: "left",
    },

    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      align: "left",
    },

    {
      title: "Payment Type",
      dataIndex: "acctype_name",
      align: "left",
    },

    {
      title: "Account Name",
      dataIndex: "cheque_or_bank_name",
      key: "cheque_or_bank_name",
      align: "left",
      render: (_, record) => {
        let color = record.cheque_or_bank_name === "DEPOSIT" ? "green" : "red";
        function status(record: any) {
          if (
            record.cheque_or_bank_name == "DEPOSIT" ||
            record.cheque_or_bank_name == "PENDING" ||
            record.cheque_or_bank_name == "BOUNCE"
          ) {
            return (
              <span style={{ color }}> {record.cheque_or_bank_name} </span>
            );
          } else {
            return `${record.account_name}`;
          }
        }
        return status(record);
      },
    },

    {
      title: "Manual Receipt No",
      dataIndex: "receipt_money_receipt_no",
      key: "receipt_money_receipt_no",
      align: "left",
    },

    {
      title: "Paid Amount",
      dataIndex: "receipt_total_amount",
      key: "receipt_total_amount",
      align: "left",
    },

    {
      title: "Doc",
      dataIndex: "receipt_total_amount",
      key: "receipt_total_amount",
      align: "left",
      render: (_, value) =>
        value?.receipt_scan_copy && (
          <Image
            src={getImageUrl(value?.receipt_scan_copy)}
            width={50}
            height={40}
          />
        ),
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (_, data, index) => (
        <Space size="small">
          {permission?.["read:any"] && (
            <Link
              to={`/moneyreceipt/view/${data.receipt_id}`}
              state={location.pathname}
            >
              <Button size="small" type="primary">
                <Typography style={{ color: "#ffffff" }}>View</Typography>
              </Button>
            </Link>
          )}

          {permission?.["update:any"] &&
            data.cheque_or_bank_name !== "DEPOSIT" && (
              <Link
                to={`/moneyreceipt/edit/${data.receipt_id}`}
                state={location.pathname}
              >
                <Button size="small" type="primary">
                  Edit
                </Button>
              </Link>
            )}
          {permission?.["delete:any"] && data.receipt_has_deleted === 0 && (
            <Popconfirm
              placement="topLeft"
              title="Sure to delete?"
              onConfirm={() => handleDelete(data, index)}
            >
              <Button
                size="small"
                type="primary"
                danger
                loading={columnIndex === index && true}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  useEffect(() => {
    const dataTemp: DataType[] =
      data?.data?.map((el, index) => {
        return {
          key: index + 1,
          receipt_has_deleted: el.receipt_has_deleted,
          acctype_name: el.acctype_name,
          client_name: el.client_name,
          receipt_payment_date: el.receipt_payment_date
            ? dayjs(el.receipt_payment_date).format("DD-MM-YYYY")
            : "",
          receipt_total_amount: Number(el.receipt_total_amount || "0"),
          receipt_vouchar_no: el.receipt_vouchar_no,
          trxntype_name: el.trxntype_name,
          receipt_money_receipt_no: el.receipt_money_receipt_no,
          receipt_id: el.receipt_id,
          account_name: el?.account_name,
          cheque_or_bank_name: el?.cheque_or_bank_name,
          mobile: el.mobile,
          receipt_scan_copy: el.receipt_scan_copy,
        };
      }) || [];
    setDataSource(dataTemp);
  }, [data]);

  // Handle Search Operation
  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <ViewInvoiceStyle>
      {(isLoading || isFetching) && <LoadingIndicator />}
      <BreadCrumb
        arrOfOption={["Money Receipts"]}
        refetch={() => refetch()}
        reloaderSize="small"
      />

      <Row
        justify={"space-between"}
        style={{ margin: "25px 0" }}
        gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
      >
        <Space>
          {permission?.["create:any"] && (
            <Link to="/moneyreceipt/add" state={location.pathname}>
              <Button type="primary">
                <PlusOutlined />
                Add Money Receipt
              </Button>
            </Link>
          )}
        </Space>

        <Col span={12}>
          <Row justify={"end"} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={"YYYY-MM-DD"}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder="🔍 Search Here..."
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        size="small"
        bordered
        rowKey={(e) => e.receipt_vouchar_no}
        columns={columns}
        dataSource={dataSource}
        pagination={
          data?.count !== undefined && data?.count > 20
            ? {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ["20", "50", "100", "200", "500"],
                onChange: handlePaginationChange,
              }
            : false
        }
      />
    </ViewInvoiceStyle>
  );
};

export default ListofMoneyReceipt;

const ViewInvoiceStyle = styled.div`
  .transfer-in-list {
    width: 100%;
  }

  .transfer-in-list .transfer-in-list-td > td {
    padding: 12px !important;
    border-bottom: 1px solid lightgray;
    font-size: 13px !important;
  }

  .transfer-in-list .ant-table-thead .ant-table-cell {
    padding: 10px !important;
    background-color: #e2e2e2;
    font-size: 13px !important;
    border-bottom: 1px solid lightgray;
  }
`;
