import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import { IAllInvoices } from '../../../../common/types/commonInterfaces';
import { IPermission } from '../../../../common/types/commonTypes';
import {
  Fixed2,
  rangePresets,
  separateClient,
} from '../../../../common/utils/common.utils';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { removeSpaceBeforeWord } from '../../../../components/common/Invoice/InvoiceListHeader';
import Reload from '../../../../components/common/reload/Reload';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import InvoiceOtherAddMoneyModal from '../../../Invoice(Other)/Modals/InvoiceOtherAddMoneyModal';
import {
  useDeleteReissueMutation,
  useGetAllInvoicesReissueQuery,
} from '../../api/endpoints/reIssueAirTicketInvoiceEndpoints';

type Props = {
  permission: IPermission | undefined;
  addMRPermission: ['*'] | undefined;
};

const ListOfInvoiceReissue = ({ permission, addMRPermission }: Props) => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useSelector(selectUser);

  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const {
    data,
    isFetching,
    isLoading: isLoadingAll,
    refetch,
  } = useGetAllInvoicesReissueQuery({
    ...pagination,
    from: date[0] || '',
    to: date[1] || '',
    search: search,
  });

  const [
    deleteInvoice,
    { isError, isSuccess, isLoading: isLoadingDelete, error },
  ] = useDeleteReissueMutation();

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  // LINKS
  const createLink = '/reissueairticket/newclient';
  const viewLink = '/reissueairticket/details';
  const editLink = '/reissueairticket/edit';
  const editListExisting = '/reissueairticket/edit/existing';

  // @ONClICK
  const handleOnDelete = (id: number, index: number) => {
    setColumnIndex(index);
    deleteInvoice({
      id,
      user_id: user?.user_id,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Invoice Deleted Successfully!');
    }
    if (isError) {
      setColumnIndex(null);
      message.error('Thare was an error');
    }
  }, [isSuccess, isError]);

  const columns: ColumnsType<IAllInvoices> = [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination?.current || 1) - 1) * (pagination?.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Invoice',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      align: 'center',
    },
    {
      title: 'Sales Date',
      key: 'invoice_date',
      align: 'center',
      render: (_, data) => (
        <>{dayjs(data.invoice_date).format('DD MMM YYYY')} </>
      ),
      sorter: (a, b) =>
        dayjs(a.invoice_date).unix() - dayjs(b.invoice_date).unix(),
    },
    {
      title: 'Client Type',
      dataIndex: 'invoice_reissue_client_type',
      key: 'invoice_reissue_client_type',
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (value, record) => {
        return (
          <Link
            to={
              record.invoice_client_id
                ? `/reports/client_ledger?client_id=${record?.invoice_client_id}`
                : `/combineClients/details/${record.invoice_combined_id}`
            }
          >
            <span>
              {record.client_name}
              {record.mobile ? ' ' + record.mobile : ''}
            </span>
          </Link>
        );
      },
      width: 150,
    },
    // {
    //   title: 'Mobile',
    //   dataIndex: 'mobile',
    //   key: 'mobile',
    //   width: 160,
    //   align: 'center',
    // },
    {
      title: 'Net Total',
      dataIndex: 'net_total',
      key: 'net_toal',
      align: 'center',

      sorter: (a, b) => Number(a.net_total) - Number(b.net_total),
      render: (_, record) => Number(record.net_total),
    },
    {
      title: 'Rec Amount',
      dataIndex: 'invclientpayment_amount',
      key: 'invclientpayment_amount',
      align: 'center',
      width: 120,
      render(value, record, index) {
        return Fixed2(value);
      },
    },
    {
      title: 'Due Amount',
      align: 'center',
      width: 120,
      render(value, record, index) {
        const dueAmount =
          Number(record?.net_total) - Number(record?.invclientpayment_amount);

        return dueAmount > 0 ? (
          <span style={{ color: 'red' }}>
            {Number(record?.net_total) -
              Number(record?.invclientpayment_amount)}
          </span>
        ) : (
          <Tag color='success'>PAID</Tag>
        );
      },
    },
    {
      title: 'MR. No.',
      dataIndex: 'money_receipt_num',
      key: 'money_receipt_num',
      width: 120,
      render: (_, data) => (
        <span>
          {data?.money_receipt_num?.split(',').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },

    {
      title: 'Sales by',
      dataIndex: 'sales_by',
      key: 'sales_by',
      width: 150,
    },

    {
      title: 'Action',
      key: 'operation',
      render: (_, data, index) => (
        <Space size='small'>
          {permission?.['read:any'] && (
            <Link
              to={viewLink + '/' + data.invoice_id}
              state={location?.pathname}
            >
              <Button size='small' type='primary'>
                View
              </Button>
            </Link>
          )}

          {permission?.['update:any'] ? (
            <Link
              to={
                data.invoice_reissue_client_type === 'NEW'
                  ? editLink + '/' + data?.invoice_id
                  : editListExisting + '/' + data?.invoice_id
              }
              state={location?.pathname}
            >
              <Button size='small' type='primary'>
                Edit
              </Button>
            </Link>
          ) : (
            ''
          )}

          {permission?.['delete:any'] &&
            !Number(data?.invclientpayment_amount || 0) &&
            data.billing_status !== 'Pending' && (
              <Button
                size='small'
                type='primary'
                danger
                loading={columnIndex === index && true}
              >
                <Popconfirm
                  onConfirm={() => handleOnDelete(data.invoice_id, index)}
                  title='Sure to delete?'
                >
                  Delete
                </Popconfirm>
              </Button>
            )}

          {addMRPermission && (
            <InvoiceOtherAddMoneyModal
              invoice_id={data.invoice_id}
              InvocieModalTitle={'Invoice (Re Issue)'}
              disabled={
                Number(data.net_total) === Number(data.invclientpayment_amount)
              }
            />
          )}
        </Space>
      ),
    },
  ];

  // Handle Search Operation

  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <>
      {/* BREADCRUMB */}
      <BreadCrumb arrOfOption={['Invoice', 'Air Ticket', 'Reissue']} />

      {/* HEADER */}
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 10 }}
        style={{ marginBottom: '25px' }}
        justify={'space-between'}
      >
        <Col span={12}>
          <Row gutter={10}>
            {permission?.['create:any'] && (
              <Col xs={24} sm={12} md={8} lg={8}>
                <Link to={createLink}>
                  <Button
                    type='primary'
                    icon={<ArrowLeftOutlined />}
                    style={{ width: '100%' }}
                  >
                    Create
                  </Button>
                </Link>
              </Col>
            )}

            <Col xs={24} sm={12} md={8} lg={8} style={{ display: 'flex' }}>
              <Col>
                <Reload
                  refetch={() => {
                    refetch();
                  }}
                />
              </Col>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='Search Invoices...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* TABLE */}
      <Table
        columns={columns}
        rowClassName={(record) =>
          record?.invoice_is_refund ? 'refund-row-style' : ''
        }
        bordered
        rowKey={(e) => e.invoice_id}
        loading={{
          spinning: isLoadingAll || isFetching || isLoadingDelete,
          indicator: loadingIndicator,
        }}
        dataSource={data?.data}
        scroll={{ x: true }}
        size='small'
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
      />
    </>
  );
};

export default ListOfInvoiceReissue;
