import { api } from "../../../../app/baseQuery";
import { HTTPResponse } from "../../../../common/types/commonTypes";
import { ACCOUNT_LIST } from "../../../Accounts/Api/Constants/constants";
import { MONEY_RECEIPT_TAG } from "../../../Money_Receipt/api/endpoints/moneyReceiptEndpoints";
import { INVALIDATE_CLIENT_VENDOR_LEDGER } from "../../../Reports/Api/constants";
import {
  DashboardSummery,
  IActiveNotice,
  IBSPBilling,
  IBestClient,
  IDashboardAccDetails,
  IExpensesData,
  ILastInvoices,
  ILoanDetails,
  IMonthlyReport,
  ISearchPostValue,
  ISearchType,
  IVendorsDetails,
  IWeeklyMonthlyDailyMonthly,
} from "../../interfaces/dashboard.interfaces";
import { DASHBOARD } from "../Constants/Constants";

export const dashboardEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all Dashboard Summary
    getDashboardSummary: build.query<HTTPResponse<DashboardSummery>, void>({
      query: () => ({ url: `/dashboard/summary` }),
      providesTags: [
        "invoices",
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        { type: MONEY_RECEIPT_TAG },
        { type: "Dashboard", id: DASHBOARD },
      ],
    }),

    // Perform Search Operation Between All Invoices
    getSearchResult: build.query<HTTPResponse<ISearchType[]>, ISearchPostValue>(
      {
        query: (body) => ({ url: `/dashboard/search?search=${body.name}` }),
        providesTags: [
          "invoices",
          INVALIDATE_CLIENT_VENDOR_LEDGER,
          { type: MONEY_RECEIPT_TAG },
          { type: "Dashboard", id: DASHBOARD },
        ],
      }
    ),

    accountBalance: build.query<HTTPResponse<ILoanDetails>, void>({
      query: (body) => ({ url: `/dashboard/acc-details` }),
      providesTags: [
        { type: "Dashboard", id: DASHBOARD },
        { type: "Accounts", id: ACCOUNT_LIST },
      ],
    }),

    expensesData: build.query<HTTPResponse<IExpensesData>, void>({
      query: (body) => ({ url: `/dashboard/expenses` }),
      providesTags: [{ type: "Dashboard", id: DASHBOARD }],
    }),

    activeNotice: build.query<HTTPResponse<IActiveNotice>, void>({
      query: (body) => ({ url: `/admin-panel/active-notice` }),
      providesTags: [{ type: "Notice" }],
    }),
    lastInvoices: build.query<HTTPResponse<ILastInvoices[]>, void>({
      query: (body) => ({ url: "/dashboard/last-invoices" }),
      providesTags: [{ type: "Notice" }],
    }),

    monthReport: build.query<HTTPResponse<IMonthlyReport[]>, void>({
      query: (body) => ({ url: `/dashboard/month-report` }),
      providesTags: [{ type: "Dashboard", id: DASHBOARD }],
    }),

    salesReport: build.query<HTTPResponse<IWeeklyMonthlyDailyMonthly>, void>({
      query: (body) => ({ url: `/dashboard/tr_info` }),
      providesTags: [{ type: "Dashboard", id: DASHBOARD }],
    }),
    vendorDetails: build.query<HTTPResponse<IVendorsDetails[]>, void>({
      query: (body) => ({ url: `/dashboard/vendors` }),
      providesTags: [{ type: "Dashboard", id: DASHBOARD }],
    }),
    bspBilling: build.query<HTTPResponse<IBSPBilling>, void>({
      query: (body) => ({ url: `/dashboard/air_ticket-summary` }),
      providesTags: [{ type: "Dashboard", id: DASHBOARD }],
    }),
    bestClient: build.query<HTTPResponse<IBestClient>, void>({
      query: (body) => ({ url: `/dashboard/best-clients` }),
      providesTags: [{ type: "Dashboard", id: DASHBOARD }],
    }),
    bestemployee: build.query<HTTPResponse<any>, void>({
      query: (body) => ({ url: `/dashboard/best-employee` }),
      providesTags: [{ type: "Dashboard", id: DASHBOARD }],
    }),
    /* ----------dashboard accounts-details--------- */
    dashboardAccDetails: build.query<
      HTTPResponse<IDashboardAccDetails[]>,
      { accType: number; pagination: { current: number; pageSize: number } }
    >({
      query: ({ accType, pagination }) => ({
        url: `/dashboard/account-details-type/${accType}?page=${pagination.current}&size=${pagination.pageSize}`,
      }),
      providesTags: [{ type: "Dashboard", id: DASHBOARD }],
    }),

    dailySalesDetails: build.query<
      HTTPResponse<{ purchase: number; payment: number }>,
      string
    >({
      query: (body) => ({ url: `/dashboard/daily-pay-pur` }),
      providesTags: [{ type: "Dashboard", id: DASHBOARD }],
    }),

    monthlySalesDetails: build.query<
      HTTPResponse<{ purchase: number; payment: number }>,
      string
    >({
      query: (body) => ({ url: `/dashboard/monthly-pay-pur` }),
      providesTags: [{ type: "Dashboard", id: DASHBOARD }],
    }),

    yearlySalesDetails: build.query<
      HTTPResponse<{ purchase: number; payment: number }>,
      string
    >({
      query: (body) => ({ url: `/dashboard/yearly-pay-pur` }),
      providesTags: [{ type: "Dashboard", id: DASHBOARD }],
    }),

    iataLimit: build.query<
      HTTPResponse<{ limit_amount: string; uses_amount: string }>,
      void
    >({
      query: (body) => ({ url: `/dashboard/iata-limit` }),
      providesTags: [{ type: "Dashboard", id: DASHBOARD }],
    }),
  }),
});

export const {
  useGetDashboardSummaryQuery,
  useLazyGetSearchResultQuery,
  useAccountBalanceQuery,
  useExpensesDataQuery,
  useActiveNoticeQuery,
  useLastInvoicesQuery,
  useMonthReportQuery,
  useSalesReportQuery,
  useVendorDetailsQuery,
  useBspBillingQuery,
  useBestClientQuery,
  useBestemployeeQuery,
  useDashboardAccDetailsQuery,
  useLazyDailySalesDetailsQuery,
  useLazyMonthlySalesDetailsQuery,
  useLazyYearlySalesDetailsQuery,

  useIataLimitQuery,
} = dashboardEndpoints;
