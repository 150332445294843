import { Row, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Fixed2, pagination } from "../../../common/utils/common.utils";

export interface IExistingClients {
  airticket_id: number;
  airticket_client_id: number;
  passport_name?: string;
  airticket_ticket_no: string;
  vendor_name: string;
  airticket_purchase_price: number;
  airticket_client_price: number;
  airticket_profit: number;
  airline_name: string;
  airticket_pnr?: number | string;
  airticket_issue_date: string;
  airticket_journey_date: string;
}

type Props = { selectClient?: IExistingClients[] };

const columns_previousTicket: ColumnsType<IExistingClients> = [
  {
    title: "Sl.",
    key: "key",
    render: (_, data, index) => <>{index + 1} </>,
  },
  {
    title: "Pax Name",
    dataIndex: "passport_name",
    key: "passport_name",
  },
  {
    title: "Ticket No.",
    dataIndex: "airticket_ticket_no",
    key: "airticket_ticket_no",
  },
  {
    title: "Vendor",
    dataIndex: "vendor_name",
    key: "vendor_name",
  },

  {
    title: "Sale Price",
    dataIndex: "airticket_client_price",
    key: "airticket_client_price",
    render: (_, data) => Fixed2(data.airticket_client_price),
  },
  {
    title: "Purchase Price",
    dataIndex: "airticket_purchase_price",
    key: "airticket_purchase_price",
    render: (_, data) => Fixed2(data.airticket_purchase_price),
  },
  {
    title: "Profit",
    dataIndex: "airticket_profit",
    key: "airticket_profit",
    render: (value) => (
      <span className={Number(value || 0) > 0 ? "text-profit" : "text-loss"}>
        {Number(value || 0)}
      </span>
    ),
  },
  {
    title: "PNR",
    dataIndex: "airticket_pnr",
    key: "airticket_pnr",
  },
  {
    title: "Issue Date",
    dataIndex: "airticket_issue_date",
    key: "airticket_issue_date",
  },
  {
    title: "Journey Date",
    dataIndex: "airticket_journey_date",
    key: "airticket_journey_date",
  },
  {
    title: "Airline",
    dataIndex: "airline_name",
    key: "airline_name",
  },
];

let totalSalesPrice: number = 0;
let totalPurchasePrice: number = 0;
let TotalProfit: number = 0;

const PreviousTicketInfo = ({ selectClient }: Props) => {
  selectClient?.map((item) => {
    totalSalesPrice = 0;
    totalPurchasePrice = 0;
    TotalProfit = 0;

    totalSalesPrice += Number(item.airticket_client_price || 0);
    totalPurchasePrice += Number(item.airticket_purchase_price || 0);
    TotalProfit += Number(item.airticket_profit || 0);
  });
  return (
    <Row justify="center" align="middle" gutter={[24, 0]}>
      <Table
        className="w-100"
        size="small"
        bordered
        dataSource={selectClient}
        columns={columns_previousTicket}
        pagination={pagination(selectClient?.length)}
        summary={(_) => {
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={4} index={0}>
                <Typography style={{ textAlign: "right" }}>Total : </Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <Typography.Text>{Fixed2(totalSalesPrice)}</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <Typography.Text> {Fixed2(totalPurchasePrice)}</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}>
                <Typography.Text>{Fixed2(TotalProfit)}</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7} colSpan={4}></Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </Row>
  );
};

export default PreviousTicketInfo;
