import { Form, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { pagination } from "../../../../common/utils/common.utils";
import { FormButton } from "../../../../components/common/FormItem/FormItems";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import {
  ITourAccmdnInfo,
  ITourClientInfo,
  ITourFoodInfo,
  ITourGuideInfo,
  ITourOtherTransInfo,
  ITourRefundInvoiceInfo,
  ITourTicketInfo,
  ITourTransportInfo,
} from "../../RefundTypes/RefundTypes";
import { TourAccomodationRefundUtils } from "../../Utils/TourPackageUtils/TourAccomodationRefundUtils";
import { TourClientRefundUtils } from "../../Utils/TourPackageUtils/TourClientRefundUtils";
import { TourFoodRefundUtils } from "../../Utils/TourPackageUtils/TourFoodRefundUtils";
import { TourGuideRefundUtils } from "../../Utils/TourPackageUtils/TourGuideRefundUtils";
import { TourOtherTransportRefundUtils } from "../../Utils/TourPackageUtils/TourOtherTransportRefundUtils";
import { TourTicketRefundUtils } from "../../Utils/TourPackageUtils/TourTicketRefundUtils";
import { TourTransportRefundUtils } from "../../Utils/TourPackageUtils/TourTransportRefundUtils";
import TourRefundForm from "./TourRefundForm";
import FormHeaderTitle from "../../../Invoice(Visa)/Components/FormHeaderTitle";

type Props = {
  data: ITourRefundInvoiceInfo;
  isLoading: boolean;
  invoice_id: number;
  refund_date: string;
  refund_note: string;
  invoice_category_id: number;
};

const TourRefundTable = ({
  data,
  isLoading,
  invoice_id,
  refund_date,
  refund_note,
  invoice_category_id,
}: Props) => {
  const [tableForm] = Form.useForm();
  const [tableSubmitData, setTableSubmitData] =
    useState<ITourRefundInvoiceInfo>();
  const [showRefundTablePage, setShowRefundTablePage] = useState<boolean>(true);
  const [showRefundFormPage, setShowRefundFormPage] = useState<boolean>(false);

  const [clientTableData, setClientTableData] = useState<ITourClientInfo[]>([]);
  const [accomtableData, setAccomTableData] = useState<ITourAccmdnInfo[]>([]);
  const [foodtableData, setFoodTableData] = useState<ITourFoodInfo[]>([]);
  const [guidetableData, setGuideTableData] = useState<ITourGuideInfo[]>([]);
  const [otherTransporttableData, setOtherTransportTableData] = useState<
    ITourOtherTransInfo[]
  >([]);
  const [tickettableData, setTicketTableData] = useState<ITourTicketInfo[]>([]);
  const [transporttableData, setTransportTableData] = useState<
    ITourTransportInfo[]
  >([]);

  //client table data
  const clientData: ITourClientInfo[] = [];

  clientData.push({
    client_refund_amount: Number(data.invoice_sub_total),
    client_refund_charge: 0,
    client_return_amount: 0,
  });
  useEffect(() => {
    setClientTableData(clientData);
  }, [clientData.length]);

  const onClientTableChargeRefundChange = (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setClientTableData((prevData) => {
      const newData = [...prevData];
      const newItem = { ...newData[index] };
      newItem.client_refund_charge = Number(value);
      newItem.client_return_amount =
        Number(newItem.client_refund_amount) - newItem.client_refund_charge;
      newData[index] = newItem;
      return newData;
    });
  };

  //accomodation data
  const accomData: ITourAccmdnInfo[] = [];
  for (let i = 0; i < data.tourAccms.length; i++) {
    const element = data.tourAccms[i];
    accomData.push({
      ...element,
      key: i + 1,
      vendor_refund_amount: Number(element.accm_cost_price),
      vendor_refund_charge: 0,
      vendor_return_amount: 0,
    });
  }

  useEffect(() => {
    setAccomTableData(accomData);
  }, [accomData.length]);

  const onAccomTableVendorChargeRefundChange = (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setAccomTableData((prevData) => {
      const newData = [...prevData];
      const newItem = { ...newData[index] };
      newItem.vendor_refund_charge = Number(value);
      newItem.vendor_return_amount =
        Number(newItem.accm_cost_price) - newItem.vendor_refund_charge;
      newData[index] = newItem;
      return newData;
    });
  };

  //food data
  const foodData: ITourFoodInfo[] = [];
  for (let i = 0; i < data.tourFoods.length; i++) {
    const element = data.tourFoods[i];
    foodData.push({
      ...element,
      key: i + 1,
      vendor_refund_amount: Number(element.food_cost_price),
      vendor_refund_charge: 0,
      vendor_return_amount: 0,
    });
  }
  useEffect(() => {
    setFoodTableData(foodData);
  }, [foodData.length]);

  const onFoodTableVendorChargeRefundChange = (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setFoodTableData((prevData) => {
      const newData = [...prevData];
      const newItem = { ...newData[index] };
      newItem.vendor_refund_charge = Number(value);
      newItem.vendor_return_amount =
        Number(newItem.food_cost_price) - newItem.vendor_refund_charge;
      newData[index] = newItem;
      return newData;
    });
  };

  //guide data
  const guideData: ITourGuideInfo[] = [];
  for (let i = 0; i < data.tourGuide.length; i++) {
    const element = data.tourGuide[i];
    guideData.push({
      ...element,
      key: i + 1,
      vendor_refund_amount: Number(element.guide_cost_price),
      vendor_refund_charge: 0,
      vendor_return_amount: 0,
    });
  }
  useEffect(() => {
    setGuideTableData(guideData);
  }, [guideData.length]);
  const onGuideTableVendorChargeRefundChange = (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setGuideTableData((prevData) => {
      const newData = [...prevData];
      const newItem = { ...newData[index] };
      newItem.vendor_refund_charge = Number(value);
      newItem.vendor_return_amount =
        Number(newItem.guide_cost_price) - newItem.vendor_refund_charge;
      newData[index] = newItem;
      return newData;
    });
  };

  //other transport data
  const otherTrasportData: ITourOtherTransInfo[] = [];
  for (let i = 0; i < data.tourOtherTrans.length; i++) {
    const element = data.tourOtherTrans[i];
    otherTrasportData.push({
      ...element,
      key: i + 1,
      vendor_refund_amount: Number(element.other_trans_cost_price),
      vendor_refund_charge: 0,
      vendor_return_amount: 0,
    });
  }
  useEffect(() => {
    setOtherTransportTableData(otherTrasportData);
  }, [otherTrasportData.length]);
  const onOtherTransTableVendorChargeRefundChange = (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setOtherTransportTableData((prevData) => {
      const newData = [...prevData];
      const newItem = { ...newData[index] };
      newItem.vendor_refund_charge = Number(value);
      newItem.vendor_return_amount =
        Number(newItem.other_trans_cost_price) - newItem.vendor_refund_charge;
      newData[index] = newItem;
      return newData;
    });
  };

  //ticket data
  const ticketData: ITourTicketInfo[] = [];
  for (let i = 0; i < data.tourTicket.length; i++) {
    const element = data.tourTicket[i];
    ticketData.push({
      ...element,
      key: i + 1,
      vendor_refund_amount: Number(element.ticket_cost_price),
      vendor_refund_charge: 0,
      vendor_return_amount: 0,
    });
  }
  useEffect(() => {
    setTicketTableData(ticketData);
  }, [ticketData.length]);
  const onTicketTableVendorChargeRefundChange = (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setTicketTableData((prevData) => {
      const newData = [...prevData];
      const newItem = { ...newData[index] };
      newItem.vendor_refund_charge = Number(value);
      newItem.vendor_return_amount =
        Number(newItem.ticket_cost_price) - newItem.vendor_refund_charge;
      newData[index] = newItem;
      return newData;
    });
  };

  //transport data
  const transportData: ITourTransportInfo[] = [];
  for (let i = 0; i < data.tourTransports.length; i++) {
    const element = data.tourTransports[i];
    transportData.push({
      ...element,
      key: i + 1,
      vendor_refund_amount: Number(element.transport_cost_price),
      vendor_refund_charge: 0,
      vendor_return_amount: 0,
    });
  }
  useEffect(() => {
    setTransportTableData(transportData);
  }, [transportData.length]);
  const onTransportTableVendorChargeRefundChange = (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setTransportTableData((prevData) => {
      const newData = [...prevData];
      const newItem = { ...newData[index] };
      newItem.vendor_refund_charge = Number(value);
      newItem.vendor_return_amount =
        Number(newItem.transport_cost_price) - newItem.vendor_refund_charge;
      newData[index] = newItem;
      return newData;
    });
  };

  //submit table form
  const onTableFormFinish = async () => {
    const body: ITourRefundInvoiceInfo = {
      tourAccms: accomtableData,
      tourFoods: foodtableData,
      tourGuide: guidetableData,
      tourOtherTrans: otherTransporttableData,
      tourTicket: tickettableData,
      tourTransports: transporttableData,
      tourBilling: data.tourBilling,
      invoice_combclient_id: data.invoice_combclient_id,
      client_name: data.client_name,
      clientInfo: clientTableData,
      client_last_balance: data.client_last_balance,
    };
    setTableSubmitData(body);
    setShowRefundTablePage(false);
    setShowRefundFormPage(true);
  };

  return (
    <div>
      {showRefundTablePage && (
        <Form form={tableForm} onFinish={onTableFormFinish}>
          <FormHeaderTitle title="Client Refund Information" />

          <Table
            size="small"
            bordered
            dataSource={clientTableData}
            columns={TourClientRefundUtils(
              onClientTableChargeRefundChange,
              "client_info"
            )}
            // rowKey={(e) => e.client_refund_charge}
            scroll={{ x: true }}
            loading={{
              spinning: isLoading,
              indicator: loadingIndicator,
            }}
            pagination={pagination(clientTableData.length)}
          />

          {accomtableData.length ? (
            <>
              <FormHeaderTitle title="Tour Refund Accomodation Information" />
              <Table
                size="small"
                bordered
                dataSource={accomtableData}
                columns={TourAccomodationRefundUtils(
                  onAccomTableVendorChargeRefundChange,
                  "accomodation"
                )}
                scroll={{ x: true }}
                loading={{
                  spinning: isLoading,
                  indicator: loadingIndicator,
                }}
                pagination={pagination(accomtableData.length)}
              />
            </>
          ) : (
            <></>
          )}

          {foodtableData.length ? (
            <>
              <FormHeaderTitle title="Tour Refund Food Information" />
              <Table
                size="small"
                bordered
                dataSource={foodtableData}
                columns={TourFoodRefundUtils(
                  onFoodTableVendorChargeRefundChange,
                  "food"
                )}
                scroll={{ x: true }}
                loading={{
                  spinning: isLoading,
                  indicator: loadingIndicator,
                }}
                pagination={pagination(foodtableData.length)}
              />
            </>
          ) : (
            <></>
          )}

          {guidetableData.length ? (
            <>
              <FormHeaderTitle title="Tour Refund Guide Information" />
              <Table
                size="small"
                bordered
                dataSource={guidetableData}
                columns={TourGuideRefundUtils(
                  onGuideTableVendorChargeRefundChange,
                  "guide"
                )}
                scroll={{ x: true }}
                loading={{
                  spinning: isLoading,
                  indicator: loadingIndicator,
                }}
                pagination={pagination(guidetableData.length)}
              />
            </>
          ) : (
            <></>
          )}
          {otherTransporttableData.length ? (
            <>
              <FormHeaderTitle title="Tour Other Transport Information" />
              <Table
                size="small"
                bordered
                dataSource={otherTransporttableData}
                columns={TourOtherTransportRefundUtils(
                  onOtherTransTableVendorChargeRefundChange,
                  "otherTrans"
                )}
                scroll={{ x: true }}
                loading={{
                  spinning: isLoading,
                  indicator: loadingIndicator,
                }}
                pagination={pagination(otherTransporttableData.length)}
              />
            </>
          ) : (
            <></>
          )}
          {tickettableData.length ? (
            <>
              <FormHeaderTitle title="Tour Ticket Information" />
              <Table
                size="small"
                bordered
                dataSource={tickettableData}
                columns={TourTicketRefundUtils(
                  onTicketTableVendorChargeRefundChange,
                  "ticket"
                )}
                scroll={{ x: true }}
                pagination={pagination(tickettableData.length)}
                loading={{
                  spinning: isLoading,
                  indicator: loadingIndicator,
                }}
              />
            </>
          ) : (
            <></>
          )}
          {transporttableData.length ? (
            <>
              <FormHeaderTitle title="Tour Transport Information" />
              <Table
                bordered
                size="small"
                dataSource={transporttableData}
                columns={TourTransportRefundUtils(
                  onTransportTableVendorChargeRefundChange,
                  "transport"
                )}
                scroll={{ x: true }}
                loading={{
                  spinning: isLoading,
                  indicator: loadingIndicator,
                }}
                pagination={pagination(transporttableData.length)}
              />
            </>
          ) : (
            <></>
          )}
          <Row style={{ marginTop: "1rem" }}>
            <FormButton label="Submit" textAlign="left" />
          </Row>
        </Form>
      )}
      {showRefundFormPage && (
        <TourRefundForm
          data={tableSubmitData}
          invoice_id={invoice_id}
          refund_date={refund_date}
          refund_note={refund_note}
          invoice_category_id={invoice_category_id}
        />
      )}
    </div>
  );
};

export default TourRefundTable;
