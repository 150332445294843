import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Form, Space, Table, theme } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  TableParams,
  handleTableChange,
} from '../../../../common/utils/common.utils';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import {
  useLazyGetExcelPassPortStatusReportQuery,
  useLazyGetPassportStatusReportQuery,
} from '../endpoints/PassportStatusReportEndpoints';
import { IPassportStatusReportFormDataTypes } from '../types/passportStatusReportTypes';
import passportStatusReportColumn from '../utils/passportStatusReportColumn';

const PassportStatusReport = () => {
  const [form] = Form.useForm();

  const [fetchExcel] = useLazyGetExcelPassPortStatusReportQuery();
  const [date_range_value, setDate_range_value] = useState<string>();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: '',
    query: '',
  });

  const [fetchPassportStatusReport, { data, isLoading }] =
    useLazyGetPassportStatusReportQuery();
  const onFinish = async (values: IPassportStatusReportFormDataTypes) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    setDate_range_value(date_range);
    const body: IPassportStatusReportFormDataTypes = {
      date_range: date_range,
      status_name: values.status_name,
    };
    await fetchPassportStatusReport({
      status_name: body.status_name,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });

    setExcelQuery({
      Id: body.status_name.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };

  const toDate = dayjs().format('YYYY-MM-DD');
  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
    form.setFieldValue('status_name', 'all');
    const date_range = `from_date=${toDate}&to_date=${toDate}`;

    fetchPassportStatusReport({
      status_name: 'all',
      query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });

    setExcelQuery({
      Id: 'all',
      query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  }, []);

  //pagination--start---
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
  }, [data]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 20 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ['20', '50', '100', '200', '500'],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IPassportStatusReportFormDataTypes = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body: IPassportStatusReportFormDataTypes = {
      date_range: date_range,
      status_name: values.status_name,
    };

    fetchPassportStatusReport({
      status_name: body.status_name,
      query: `${query}&${body.date_range}`,
    });
    setExcelQuery({
      Id: body.status_name.toString(),
      query: `${query}&${body.date_range}`,
    });
  };
  //pagination--end---
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Passport_Wise_Report`,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
      }}
    >
      <Table
        size='small'
        bordered
        pagination={count > 20 ? tableParams.pagination : false}
        columns={passportStatusReportColumn(queryData)}
        dataSource={data?.data}
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
        onChange={(args1, args2, args3) =>
          handleTableChange({
            args: {
              pagination: args1,
              filters: args2,
              sorter: args3,
            },
            states: {
              refetch,
              setQueryData,
              setTableParams,
              tableParams,
            },
          })
        }
      />
    </ConfigProvider>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Passport Status Report' }}
        extraInfo={{
          date:
            date_range_value
              ?.split('&')
              ?.map((param) => dayjs(param.split('=')[1])?.format('DD-MM-YYYY'))
              ?.join(' to ') || '',
        }}
      />
    </div>
  );
  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'Passport Status Report']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: 'passport/passportStatusReport',
              excelName: 'passportStatusReport',
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <ReportsHeader
          date_range
          showPassPortStatus
          showAll
          required
          reports_title='Passport Status Report'
        />
      </Form>

      {
        <div>
          {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
          {jsx_com}

          {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
          {print_content}
        </div>
      }
    </>
  );
};

export default PassportStatusReport;
