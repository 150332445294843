import { Button, Form, Space, Table } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useLazyGetAllVoidQuery } from '../endpoints/void_and_delete_endpoint';
import { void_utils } from '../utils/void_utils';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';

type Props = {};

const Void_Invoices = (props: Props) => {
  const [form] = Form.useForm();
  const [from, setFrom] = useState(dayjs().format('YYYY-MM-DD'));
  const [to, setTo] = useState(dayjs().format('YYYY-MM-DD'));

  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
  }, []);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const [getData, { isLoading, isFetching, data }] = useLazyGetAllVoidQuery();

  useEffect(() => {
    getData({
      current: pagination.current,
      pageSize: pagination.pageSize,
      from_date: from,
      to_date: to,
    });
  }, [pagination]);

  useEffect(() => {
    getData({
      current: pagination.current,
      pageSize: pagination.pageSize,
      from_date: dayjs().format('YYYY-MM-DD'),
      to_date: dayjs().format('YYYY-MM-DD'),
    });
  }, []);

  const dataLength: any = data?.data?.length;

  const onFinish = (values: any) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    setFrom(setFrom_date);
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    setTo(setTo_date);

    getData({
      current: pagination.current,
      pageSize: pagination.pageSize,
      from_date: dayjs(values.date_range[0]).format('YYYY-MM-DD'),
      to_date: dayjs(values.date_range[1]).format('YYYY-MM-DD'),
    });
  };
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Void_and_delete_invoices_report`,
  });

  const jsx_content = (
    <>
      <Table
        size='small'
        bordered
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        columns={void_utils({ pagination })}
        dataSource={data?.data}
        loading={{
          spinning: isLoading || isFetching,
          indicator: <LoadingIndicator />,
        }}
      />
    </>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_content}
        printRef={componentRef}
        title={{ info_title: '', title: 'Void and Delete Invoices Report' }}
        extraInfo={{
          date:
            `${dayjs(from).format('DD-MM-YYYY')} to ${dayjs(to).format(
              'DD-MM-YYYY'
            )}` || '',
        }}
      />
    </div>
  );
  return (
    <div>
      <BreadCrumb
        arrOfOption={['Void and Delete Invoices']}
        reloaderSize='small'
      />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
      </Space>

      <Form layout='vertical' form={form} onFinish={onFinish}>
        <ReportsHeader
          select_placeholder='All'
          date_range
          required
          reports_select
        />
      </Form>

      {/* //----------------//------------// @ TABLE HERE //----------------//------------// */}
      <FormHeaderTitle title='Void and Delete Invoices Report' />
      {jsx_content}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </div>
  );
};

export default Void_Invoices;
