import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Form, Space, message } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectUser } from "../../../auth/states/userSlice";
import { FormButton } from "../../../components/common/FormItem/FormItems";
import { SelectClients } from "../../../components/common/FormItem/SelectCustomFeilds";
import BreadCrumb from "../../../components/common/breadCrumb/BreadCrumb";
import LoadingIndicator from "../../../components/common/spinner/LoadingIndicator";

import { useCreatePassportMutation } from "../Api/Endpoints/PassportEndpoints";
import PassportInfoFormList from "../Components/PassportInfoFormList";
import { IPassportAddFormData } from "../PassportTypes/PassportTypes";
import dayjs from "dayjs";

type Props = {
  useAsModal?: boolean;
  setCreatePassportModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setClick?: (arg: boolean) => void;
  setInstancPassportSelect?:
    | React.Dispatch<React.SetStateAction<number | undefined>>
    | undefined;
};

const AddPassport = ({
  useAsModal,
  setCreatePassportModal,
  setClick,
  setInstancPassportSelect,
}: Props) => {
  const user = useSelector(selectUser);
  const MODULE_TYPE = user?.organization_info?.org_module_type || "TRABILL";
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [addPassport, { isError, isLoading, isSuccess, data }] =
    useCreatePassportMutation();

  useEffect(() => {
    if (isSuccess) {
      message.success("Passport added successfully!");
      !useAsModal && navigate("/passport");
      setCreatePassportModal && setCreatePassportModal(false);
      setClick && setClick(false);
      setInstancPassportSelect && setInstancPassportSelect(Number(data?.data));
      form.resetFields();
    }
  }, [isSuccess]);

  const onFinish = async (values: IPassportAddFormData) => {
    const { client_id, passport_info, passport_rec_cl_id } = values;

    for (const passportData of passport_info) {
      const formData = new FormData();

      const PassportData = {
        passport_no: passportData.passport_no,
        name: passportData.name,
        passport_person_type: passportData.passport_person_type,
        email: passportData.email,
        nid: passportData.nid,
        mobile_no: passportData.mobile_no,
        passport_note: passportData.passport_note,
        date_of_birth: dayjs(passportData.date_of_birth).format("YYYY-MM-DD"),
        date_of_issue: dayjs(passportData.date_of_issue).format("YYYY-MM-DD"),
        date_of_expire: dayjs(passportData.date_of_expire).format("YYYY-MM-DD"),
      };

      if (passportData.scan_copy) {
        formData.append(
          `passport_scan_copy`,
          passportData.scan_copy[0].originFileObj
        );
      }

      if (passportData.upload_photo) {
        formData.append(
          `passport_upload_photo`,
          passportData.upload_photo[0].originFileObj
        );
      }

      if (passportData.upload_others) {
        formData.append(
          `passport_upload_others`,
          passportData.upload_others[0].originFileObj
        );
      }

      if (client_id) formData.append("client_id", client_id?.toString());
      if (passport_rec_cl_id)
        formData.append("passport_rec_cl_id", passport_rec_cl_id?.toString());

      formData.append("passport_info", JSON.stringify(PassportData));
      formData.append("passport_created_by", String(user?.user_id));

      await addPassport(formData);
    }
  };
  return (
    <div>
      {!useAsModal && <BreadCrumb arrOfOption={["Create Passport"]} />}

      {!useAsModal && (
        <Space>
          <Link to="/passport">
            <Button type="primary" style={{ marginBottom: "1rem" }}>
              <ArrowLeftOutlined />
              Return to Passport List
            </Button>
          </Link>
        </Space>
      )}

      <Form
        style={{ marginTop: 10 }}
        encType="multipart/form-data"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Card title="Passport Information" style={{ marginBottom: "1rem" }}>
          {MODULE_TYPE === "TRABILL" && (
            <SelectClients name="client_id" label="Select Client" size={6} />
          )}

          <PassportInfoFormList form={form} useAsModal={useAsModal} />
        </Card>

        <FormButton loading={isLoading} label="Create Passport" />
      </Form>
      {isLoading && <LoadingIndicator />}
    </div>
  );
};

export default AddPassport;
