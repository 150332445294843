import { Divider } from "antd";
import { useWatch } from "antd/es/form/Form";
import { FormInstance } from "antd/lib/form";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { HTTPResponse } from "../../../common/types/commonTypes";
import { Fixed2 } from "../../../common/utils/common.utils";
import {
  FormInputItem,
  NumberInput,
} from "../../../components/common/FormItem/FormItems";
import { SelectVendorsByInvId } from "../../../components/common/FormItem/SelectCustomFeilds";
import {
  IAddVendorPayment,
  IGetVendorByInvNumber,
  ISpecific_Inv_Vendors_Payment,
} from "../types/vendor.interfaces";

type Props = {
  index: number;
  vendorByInvData?: HTTPResponse<IGetVendorByInvNumber[]>;
  form: FormInstance<any>;
  prevData?: IAddVendorPayment;
  disableRemoveVendorIndex?: number | undefined;
  disableEdit?: any;
};

export default function PaymentVendorListSubComponent({
  index,
  vendorByInvData,
  form,
  prevData,
  disableRemoveVendorIndex,
  disableEdit,
}: Props) {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (
      disableEdit &&
      disableRemoveVendorIndex &&
      disableRemoveVendorIndex > index
    ) {
      setDisabled(true);
    }
  }, [disableRemoveVendorIndex, disableEdit]);

  const comb_vendor_specific_invoice = useWatch(
    ["specific_inv_vendors", index, "comb_vendor_specific_invoice"],
    form
  );

  const { vpay_id } = useParams();

  useEffect(() => {
    if (comb_vendor_specific_invoice) {
      const findData = vendorByInvData?.data?.find(
        (item) => item.comb_vendor === comb_vendor_specific_invoice
      );

      // const editTimeAmount =
      //   (prevData?.specific_inv_vendors &&
      //     Number(
      //       prevData?.specific_inv_vendors[index]?.specific_inv_amount || 0
      //     )) ||
      //   0;
      const editTimeAmount = false;

      form.setFieldValue(
        ["specific_inv_vendors", index, "specific_inv_vendor_total"],
        editTimeAmount || Fixed2(findData?.cost_price) || 0
      );
      form.setFieldValue(
        ["specific_inv_vendors", index, "specific_inv_vendor_paid"],
        Fixed2(findData?.cost_price) - Fixed2(findData?.due_amount)
      );
      form.setFieldValue(
        ["specific_inv_vendors", index, "specific_inv_vendor_due"],
        editTimeAmount || Fixed2(findData?.due_amount) || 0
      );
    }
  }, [comb_vendor_specific_invoice]);

  const paid = Fixed2(
    useWatch(["specific_inv_vendors", index, "specific_inv_vendor_paid"], form)
  );
  const due = Fixed2(
    useWatch(["specific_inv_vendors", index, "specific_inv_vendor_due"], form)
  );

  return (
    <>
      {index !== 0 ? <Divider /> : ""}
      <SelectVendorsByInvId
        name={[index, "comb_vendor_specific_invoice"]}
        label="Vendors:"
        size={4}
        mdSize={6}
        smSize={6}
        disabled={disabled}
        required
        manualData={vendorByInvData?.data?.map((item) => ({
          vendor_id: item?.comb_vendor?.split("-")[1],
          vendor_name: item.vendor_name,
          vendor_type: item?.comb_vendor?.split("-")[0] as
            | "vendor"
            | "combined",
        }))}
        rules={[
          ({ getFieldValue }) => ({
            validator: (_, value) => {
              const specific_inv_vendors: ISpecific_Inv_Vendors_Payment[] =
                getFieldValue(["specific_inv_vendors"]);
              const isFound = specific_inv_vendors?.filter(
                (item, indexI) =>
                  indexI !== index &&
                  item?.comb_vendor_specific_invoice === value
              );

              if (isFound.length) {
                return Promise.reject();
              } else {
                return Promise.resolve();
              }
            },
            message: "Vendor can not be same!",
          }),
        ]}
      />

      <FormInputItem
        name={[index, "specific_inv_vendor_total"]}
        label={`Total: `}
        size={4}
        mdSize={6}
        smSize={6}
        maxChar={10}
        disabled={disabled}
        minChar={0}
        readOnly
      />

      <FormInputItem
        name={[index, "specific_inv_vendor_paid"]}
        label={`Paid: `}
        size={4}
        mdSize={6}
        smSize={6}
        maxChar={10}
        disabled={disabled}
        minChar={0}
        readOnly
      />

      <FormInputItem
        name={[index, "specific_inv_vendor_due"]}
        label="Due:"
        size={4}
        mdSize={6}
        smSize={6}
        maxChar={10}
        disabled={disabled}
        minChar={0}
        readOnly
      />

      <NumberInput
        name={[index, "specific_inv_amount"]}
        label="Amount:"
        size={4}
        mdSize={6}
        smSize={6}
        maxChar={10}
        disabled={disabled}
        minChar={0}
        required
        max={vpay_id ? paid + due : due}
        min="0"
        rules={[
          ({ getFieldValue }) => ({
            validator(rule, value, callback) {
              if (value) {
                if (vpay_id && !(value < due + paid)) {
                  return Promise.reject();
                }
                if (!vpay_id && value > due) {
                  return Promise.reject();
                }
              }
              return Promise.resolve();
            },
            message: "Amount can't greater than Due!",
          }),
        ]}
      />
    </>
  );
}
