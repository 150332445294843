import { Form } from "antd";
import { FormInstance } from "antd/lib/form";
import { useEffect } from "react";
import { useParams } from "react-router";
import NewExistingClientBillingComponent from "./NewExistingClientBillingComponent";

const NewExistingClientBillingInfoForm = ({
  form,
  selectClient,
}: {
  form: FormInstance<any>;
  selectClient: any;
}) => {
  const { id } = useParams();
  //   const [handleTicketNo, setHandleTicketNo] = useState<string>('');
  //   const [ticketIsExist, { data: ticketIsExisting }] =
  //     useLazyTicketIsExistQuery();

  //   useEffect(() => {
  //     const ticketFetcing = setTimeout(async () => {
  //       if (handleTicketNo) {
  //         await ticketIsExist(handleTicketNo);
  //         const ticket = form.getFieldValue('airticket_ticket_no');
  //         if (ticket) {
  //           form.validateFields(['airticket_ticket_no']);
  //         }
  //       }
  //     }, 800);
  //     return () => clearTimeout(ticketFetcing);
  //   }, [handleTicketNo]);

  console.log({ selectClient });

  useEffect(() => {
    if (
      selectClient &&
      Array.isArray(selectClient) &&
      selectClient.length > 0
    ) {
      const mappedClients = selectClient.map((item: any) => ({
        existing_ticket_no: item.airticket_ticket_no,
        airticket_existing_invoiceid: item.airticket_invoice_id,
        airticket_existing_airticket_id: item.airticket_id,
        passport_name: item?.passport_name,
      }));

      form.setFieldsValue({
        existingClient: mappedClients,
      });
    }
  }, [selectClient, form, id]);

  return (
    <>
      <Form.List name="existingClient">
        {(fields, { add, remove }) => (
          <>
            {fields?.map(({ key, name, ...restField }) => (
              <NewExistingClientBillingComponent
                key={key}
                name={name}
                restField={restField}
                form={form}
              />
            ))}
          </>
        )}
      </Form.List>
    </>
  );
};

export default NewExistingClientBillingInfoForm;
