import { FileTextOutlined, PrinterOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useReactToPrint } from "react-to-print";
import {
  Fixed2,
  TableParams,
  findColumnsWithNull,
  handleTableChange,
  rangePresets,
} from "../../../../common/utils/common.utils";
import { FormButton } from "../../../../components/common/FormItem/FormItems";
import { SelectVendorsOnly } from "../../../../components/common/FormItem/SelectCustomFeilds";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import FormHeaderTitle from "../../../Invoice(Visa)/Components/FormHeaderTitle";
import { ISingleVendorDetails } from "../../../vendor/types/vendor.interfaces";
import { useLazyGetExcelQuery } from "../../ledgers/endpoints/ledgerEndpoints";
import { useLazyGetVendorLedgerbyIDQuery } from "../endpoints/ledgerEndpoints";
import {
  IVendorLedger,
  IVendorLedgerFormData,
} from "../types/vendorLedgerTypes";
import { VendorLedgerUtils } from "../utils/VendorLedgerUtils";
import { landscapePageStyle } from "./ClientLedgers";
import { useLocation } from "react-router-dom";
type Props = {
  vendorInfo?: ISingleVendorDetails;
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function VendorLedgers({ vendorInfo }: Props) {
  const query = useQuery();
  const queryId = query.get("vendor_id");

  useEffect(() => {
    if (queryId) {
      form.setFieldValue("vendor_id", Number(queryId));
      form.setFieldValue("date_range", [dayjs().subtract(1, "month"), dayjs()]);
      form.submit();
    } else {
      form.setFieldValue("date_range", [dayjs(), dayjs()]);
    }
  }, [queryId]);
  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: "0" | "1";
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: "0", pageSize: 20 });

  const columns: ColumnsType<IVendorLedger> = VendorLedgerUtils(queryData);

  const [
    fetchvendorLedger,
    { data: vendorLedger, isLoading, isSuccess, isFetching },
  ] = useLazyGetVendorLedgerbyIDQuery();

  // Array to store columns with null or falsy values for each row
  const columnsWithNullForEachRow: any[][] = [];

  // Iterate through each object in the array
  vendorLedger?.data?.ledgers?.forEach((obj: any) => {
    const columnsWithNull = findColumnsWithNull(obj);
    columnsWithNullForEachRow.push(columnsWithNull);
  });
  // Find the intersection of columns with null or falsy values for each row
  const commonColumnsWithNull =
    columnsWithNullForEachRow.length > 0
      ? columnsWithNullForEachRow.reduce((intersection, columns) => {
          return intersection.filter((value) => columns.includes(value));
        })
      : [];

  // Assuming columns is an array of objects with a 'key' property
  const filteredColumns = columns.filter((item) => {
    const keysToRemove = commonColumnsWithNull;

    // Check if any key should be removed
    if (!keysToRemove.includes(item.key as string)) {
      return item;
    }
  });

  const { id } = useParams();
  const [form] = Form.useForm();
  const [date_range_value, setDate_range_value] = useState<string>();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: "",
    query: "",
  });
  const [fetchExcel] = useLazyGetExcelQuery();

  // vendors

  const vendorID = vendorInfo?.vendor_id;

  useEffect(() => {
    if (vendorID) {
      form.setFieldValue("date_range", [dayjs().subtract(1, "month"), dayjs()]);
      form.setFieldValue("vendor_id", vendorID);
    }
    if (vendorID !== undefined) {
      message.info("Showing last month record");
      form.submit();
    }
  }, [vendorID]);

  const vendor = vendorLedger?.data?.vendor;

  const vendorInfoData = [
    {
      key: "1",
      name: "Name",
      value: vendor?.name,
    },
    {
      key: "2",
      name: "Email",
      value: vendor?.email,
    },
    {
      key: "3",
      name: "Mobile",
      value: vendor?.vendor_mobile,
    },

    {
      key: "4",
      name: "Address",
      value: vendor?.address,
    },
  ];

  const vendorColumn = [
    {
      dataIndex: "name",
      key: "name",
      width: "150px",
    },
    {
      dataIndex: "value",
      key: "value",
      width: "120px",
    },
  ];

  const onFinish = async (values: IVendorLedgerFormData) => {
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body: IVendorLedgerFormData = {
      vendor_id: id ? Number(id) : values.vendor_id,
      date_range: date_range,
    };

    fetchvendorLedger({
      vendor_id: body.vendor_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setDate_range_value(date_range);
    setExcelQuery({
      Id: body.vendor_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };
  console.log(date_range_value);
  //pagination
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(vendorLedger?.count || 0);
  }, [vendorLedger]);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ["20", "50", "100", "200", "500"],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IVendorLedgerFormData = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body: IVendorLedgerFormData = {
      vendor_id: id ? Number(id) : values.vendor_id,
      date_range: date_range,
    };

    fetchvendorLedger({
      vendor_id: body.vendor_id,
      query: `${query}&${body.date_range}`,
    });
    setExcelQuery({
      Id: body.vendor_id.toString(),
      query: `${query}&${body.date_range}`,
    });
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Vendor_Ledger_${date_range_value}`,
    pageStyle: landscapePageStyle,
  });

  const LastPage = Math.ceil(
    Number(vendorLedger?.count || 0) / (tableParams?.pagination?.pageSize || 0)
  );
  // @----//-----------//------------HANDLE PRINT---------//------------------//---
  const ledger_content = vendorLedger?.data ? (
    <ConfigProvider theme={{}}>
      {vendorLedger?.data?.ledgers?.length ? (
        <div className="my-20">
          <Typography.Text style={{ margin: 10 }}>
            You have total: {count} rows
          </Typography.Text>
          <Table
            size="small"
            bordered
            rowKey={(e) => e.vtrxn_id}
            className="invoiceBillingTable scrollBarVisible"
            rowClassName={"invoiceBillingTd"}
            pagination={count > 20 ? tableParams.pagination : false}
            dataSource={vendorLedger?.data?.ledgers}
            columns={VendorLedgerUtils(queryData)}
            loading={{
              spinning: isLoading || isFetching,
              indicator: loadingIndicator,
            }}
            onChange={(args1, args2, args3) =>
              handleTableChange({
                args: {
                  pagination: args1,
                  filters: args2,
                  sorter: args3,
                },
                states: {
                  refetch,
                  setQueryData,
                  setTableParams,
                  tableParams,
                },
              })
            }
            summary={(_) => {
              if (tableParams.pagination?.current === LastPage)
                return (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={8}>
                      <div className="">
                        <Typography.Text strong>Total: </Typography.Text>
                      </div>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={1} colSpan={2}>
                      <div style={{ textAlign: "right" }}>
                        <Typography.Text style={{ color: "red" }}>
                          {Fixed2(vendorLedger.data?.summary_amount.total_dr)}
                        </Typography.Text>
                      </div>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={2} colSpan={2}>
                      <div style={{ textAlign: "left" }}>
                        <Typography.Text
                          style={{ color: "green", marginLeft: 2 }}
                        >
                          {Fixed2(vendorLedger.data?.summary_amount.total_cr)}
                        </Typography.Text>
                      </div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} colSpan={3} />
                  </Table.Summary.Row>
                );
            }}
          />
        </div>
      ) : (
        ""
      )}
    </ConfigProvider>
  ) : (
    <></>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={ledger_content}
        printRef={componentRef}
        extraInfo={{
          address: vendor?.address || "",
          email: vendor?.email || "",
          mobile: vendor?.vendor_mobile || "",
          name: vendor?.name || "",
          date:
            date_range_value
              ?.split("&")
              ?.map((param) => dayjs(param.split("=")[1])?.format("DD-MM-YYYY"))
              ?.join(" to ") || "",
        }}
        title={{ info_title: "Vendor Details", title: "Vendor Ledger" }}
      />
    </div>
  );

  return (
    <div id="vendor_ledger">
      {!(id || vendorID) && (
        <BreadCrumb arrOfOption={["Report", " Vendor Ledger"]} />
      )}
      <Space style={{ marginBottom: "1rem" }}>
        <Button type="primary" onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>

        <Button
          type="primary"
          onClick={() => {
            if (vendorLedger?.data?.ledgers.length)
              fetchExcel({
                client_id: excelQuery.Id,
                query: excelQuery.query,
                excelName: "Vendor_Excel_Report",
                excelApiName: "vendor_ledger_excel",
              });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>

      {/* vendor info */}
      <Row justify={"space-between"}>
        <Col>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Row gutter={[5, 0]}>
              <SelectVendorsOnly
                required
                vendorID={vendorID}
                name="vendor_id"
                size={8}
                mdSize={10}
                xsSize={10}
                smSize={10}
                label="Select Vendor"
                placeholder="Select Vendor"
              />

              <Col xs={12} sm={10} md={12} lg={12}>
                <Form.Item
                  label="Date Range"
                  name="date_range"
                  rules={[
                    { required: true, message: ` Date Range is required!` },
                  ]}
                >
                  <DatePicker.RangePicker
                    presets={rangePresets}
                    format={"DD-MM-YYYY"}
                    allowClear={false}
                    // style={{ width: '250px' }}
                  />
                </Form.Item>
              </Col>
              <Col lg={4} xs={24} sm={2} md={2} push={1}>
                <FormButton
                  label="Search"
                  icon
                  textAlign="left"
                  loading={isLoading}
                />
              </Col>
            </Row>
          </Form>
        </Col>
        {vendor && (
          <Col className="p-5 ">
            <FormHeaderTitle title="Vendor Details" />
            <Table
              size="small"
              bordered
              className="invoiceBillingTable"
              rowClassName={"invoiceBillingTd"}
              rowKey={(e) => e.key}
              dataSource={vendorInfoData}
              columns={vendorColumn}
              showHeader={false}
              pagination={false}
              locale={{ emptyText: " " }}
            />
          </Col>
        )}
      </Row>

      {/* @LEDGER CONTENTS */}
      {ledger_content}

      {/* @PRINTABLE CONTENT */}
      {print_content}
    </div>
  );
}

export default VendorLedgers;
