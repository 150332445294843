import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import { useAppSelector } from "../../../app/hooks";
import { HTTPResponse } from "../../../common/types/commonTypes";
import {
  IAddVendorPayment,
  IGetVendorByInvNumber,
} from "../types/vendor.interfaces";
import PaymentVendorListSubComponent from "./PaymentVendorListSubComponent";

type Props = {
  form: FormInstance<any>;
  vendorByInvData?: HTTPResponse<IGetVendorByInvNumber[]>;
  prevData?: IAddVendorPayment;
};

export default function PaymentVendorList({
  form,
  vendorByInvData,
  prevData,
}: Props) {
  const role_name = useAppSelector((state) => state.user?.role_name);
  const editPermission = useAppSelector((sate) => sate.user?.role_permissions);
  const editPermissionParse = editPermission
    ? JSON.parse(editPermission)
    : undefined;
  const disableEdit = editPermissionParse[role_name!]?.vendor_payment_specific;

  const disableRemoveVendorIndex = prevData?.specific_inv_vendors?.length;

  return (
    <Row style={{ display: "flex" }} justify={"start"}>
      <Divider orientation="left">Select vendors:</Divider>

      <Form.List name="specific_inv_vendors" initialValue={[{}]}>
        {(fields, { add, remove }) =>
          fields.map((field, index) => {
            return (
              <Row key={index} align="bottom">
                <PaymentVendorListSubComponent
                  form={form}
                  index={index}
                  key={index}
                  vendorByInvData={vendorByInvData}
                  prevData={prevData}
                  disableRemoveVendorIndex={disableRemoveVendorIndex}
                  disableEdit={disableEdit}
                />

                <Col xs={3} sm={3} md={3} lg={3}>
                  <Form.Item>
                    {index === 0 ? (
                      <Button type="primary" onClick={() => add()}>
                        <PlusOutlined />
                      </Button>
                    ) : (
                      <>
                        <Divider />
                        <Button
                          type="primary"
                          danger
                          disabled={
                            disableEdit
                              ? disableRemoveVendorIndex
                                ? disableRemoveVendorIndex > index
                                : false
                              : false
                          }
                          onClick={() => remove(field.name)}
                        >
                          <MinusOutlined />
                        </Button>
                      </>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            );
          })
        }
      </Form.List>
      <Divider />
    </Row>
  );
}
