import {
  FileExcelFilled,
  MessageOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Modal,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
  TableColumnsType,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectUser } from "../../../../auth/states/userSlice";
import { selectCommon, setModal } from "../../../../common/slices/commonSlice";
import { IPermission, crud } from "../../../../common/types/commonTypes";
import { getRowHightLightClass } from "../../../../common/utils/rowColorChange";
import { FormInputItem } from "../../../../components/common/FormItem/FormItems";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import {
  useActivateClientMutation,
  useDeleteClientMutation,
  useGetClientsQuery,
  useLazyGetClientExcelDataQuery,
} from "../api/endpoints/clientEndpoints";
import ClientSmsModal from "../modals/ClientSmsModal";
import { ClientDataType } from "../types/clientInterfaces";
import {
  formatAmount,
  formatAmountAbs,
} from "../../../../common/utils/common.utils";

const ClientLists = ({ permission }: { permission?: IPermission }) => {
  const [editInfo, setEditInfo] = useState<ClientDataType | null>(null);
  const dispatch = useAppDispatch();
  const commonState = useSelector(selectCommon);

  //   Excel Download
  const [fetchExcel] = useLazyGetClientExcelDataQuery();
  // Get Data with Pagination and Search
  const [search, setSearch] = useState("");

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
    // setExcelQuery({ ...pagination, search: search });
  };

  const {
    data: clients,
    isLoading: isClientLoading,
    isFetching,
    refetch,
  } = useGetClientsQuery({ ...pagination, search: search });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 20,
    }));
  }, [search]);

  const showModal = () => {
    dispatch(setModal(true));
  };

  const [activate, { isLoading }] = useActivateClientMutation();

  const handleModal = () => {
    dispatch(setModal(false));
  };

  const loading = {
    spinning: isClientLoading || isFetching,
    indicator: loadingIndicator,
  };

  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const [changeStateId, setChangeStateId] = useState<number>();

  const handleClick = (record: ClientDataType) => {
    setEditInfo(record);
  };
  const user = useAppSelector(selectUser);
  const [deleteClient, { isSuccess, isError }] = useDeleteClientMutation();

  const handleClientDelete = async (values: any, index: number) => {
    setColumnIndex(index);
    await deleteClient({
      ...values,
      client_updated_by: user?.user_id,
    });
  };

  // CLIENTS CLIENT
  const columns: TableColumnsType<ClientDataType> = [
    {
      title: "Sl.",
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: "Unique Id",
      dataIndex: "client_entry_id",
      key: "client_entry_id",
    },
    {
      title: "Name",
      dataIndex: "client_name",
      key: "client_name",

      render: (_, { client_name, client_id }) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Link to={`/reports/client_ledger?client_id=${client_id}`}>
              {" "}
              {client_name}
            </Link>
          </div>
        );
      },
    },
    {
      title: "Client Type",
      dataIndex: "client_type",
      key: "client_type",
    },
    {
      title: "Mobile",
      dataIndex: "client_mobile",
      key: "client_mobile",
    },
    {
      title: "Email",
      dataIndex: "client_email",
      key: "client_email",
    },

    {
      title: "Present Balance",
      dataIndex: "client_lbalance",
      key: "client_lbalance",
      align: "right",
      // render: (curr) => {
      //   return Number(curr || 0) < 0 ? (
      //     <span style={{ color: 'red' }}>{Math.abs(curr)}</span>
      //   ) : (
      //     <span style={{ color: 'green' }}>{Math.abs(curr)}</span>
      //   );
      // },
      render: (_, record) => {
        const { client_lbalance } = record;
        return (
          <>
            {client_lbalance != undefined && Number(client_lbalance) > 0 && (
              <span className="text-profit">
                {formatAmount(client_lbalance)}
              </span>
            )}
            {Number(client_lbalance) == 0 && formatAmount(client_lbalance)}
            {client_lbalance != undefined && Number(client_lbalance) < 0 && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  type="text"
                  size="small"
                  onClick={() => {
                    handleClick(record);
                    showModal();
                  }}
                >
                  <MessageOutlined />
                </Button>
                <span className="text-due">
                  {formatAmountAbs(client_lbalance)}
                </span>
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "user_full_name",
      key: "user_full_name",
    },
    {
      title: "Status",
      dataIndex: "client_activity_status",
      key: "client_activity_status",

      render: (_, { client_activity_status, client_id }) => (
        <>
          <Popconfirm
            title={`${
              client_activity_status ? "Sure to Inactive?" : "Sure to active?"
            }`}
            onConfirm={() => {
              setChangeStateId(client_id);
              activate({
                client_id,
                status: client_activity_status === 0 ? "active" : "inactive",
                created_by: user?.user_id as number,
              });
            }}
          >
            <Switch
              style={{
                backgroundColor: client_activity_status ? "green" : "#ff4d4f",
              }}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              checked={client_activity_status ? true : false}
            />
          </Popconfirm>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",

      render: (_, record, index) => (
        <Space size="small">
          {permission?.["read:any"] && (
            <Link
              to={`/clients/details/client-${record.client_id}`}
              state={location.pathname}
            >
              <Button size="small" type="primary">
                <Typography style={{ color: "#ffffff" }}>View</Typography>
              </Button>
            </Link>
          )}

          {permission?.[crud.update] && (
            <Link
              to={`/clients/edit/client-${record.client_id}`}
              state={location.pathname}
            >
              <Button size="small" type="primary">
                <Typography style={{ color: "#ffffff" }}>Edit </Typography>
              </Button>
            </Link>
          )}

          {permission?.[crud.delete] ? (
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleClientDelete(record, index)}
            >
              <Button
                danger
                size="small"
                type="primary"
                loading={columnIndex === index && true}
              >
                <Typography style={{ color: "#fff" }}>Delete </Typography>
              </Button>
            </Popconfirm>
          ) : (
            <Button disabled danger size="small" type="primary">
              <Typography>Delete </Typography>
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const handleSearch = (e: any) => {
    const search = e.target.value;
    setSearch(search);
  };

  return (
    <ClientsStyle>
      <BreadCrumb
        arrOfOption={["Clients"]}
        refetch={() => refetch()}
        reloaderSize="small"
      />
      <Modal
        title="Send SMS to Client"
        open={commonState.isModal}
        okText={"Submit"}
        cancelText={"Close"}
        onOk={handleModal}
        onCancel={handleModal}
        footer={null}
      >
        {editInfo && <ClientSmsModal info={editInfo} setNull={setEditInfo} />}
      </Modal>
      <Row justify={"space-between"}>
        <Col lg={4}>
          <Space style={{ marginBottom: "1rem" }}>
            {permission?.[crud.create] && (
              <Link to="/clients/add" state={location.pathname}>
                <Button type="primary">
                  <PlusOutlined />
                  Add Client
                </Button>
              </Link>
            )}
            <Button
              type="primary"
              onClick={() => {
                fetchExcel({ ...pagination, search: search });
              }}
            >
              <FileExcelFilled />
              Excel Report
            </Button>
          </Space>
        </Col>

        <FormInputItem
          onBlur={handleSearch}
          onPressEnter={handleSearch}
          placeholder="🔍 Search by clients"
          size={6}
        />
      </Row>

      <Table
        size="small"
        rowClassName={(item) => getRowHightLightClass(item.client_created_date)}
        bordered
        columns={columns}
        dataSource={clients?.data}
        scroll={{ x: true }}
        loading={loading}
        pagination={
          clients?.count !== undefined && clients?.count < 20
            ? false
            : {
                ...pagination,
                total: clients?.count,
                showSizeChanger: true,
                pageSizeOptions: ["20", "50", "100", "200", "500"],
                onChange: handlePaginationChange,
              }
        }
      />
    </ClientsStyle>
  );
};

export default ClientLists;

const ClientsStyle = styled.div``;
