import { PrinterOutlined } from '@ant-design/icons';
import { Button, Form, Radio, RadioChangeEvent, Space, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  TableParams,
  handleTableChange,
} from '../../../../common/utils/common.utils';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useLazyGetAllOtherTaxReportQuery } from '../endpoints/TaxReportEndpoints';
import { ITaxReportFormDataType } from '../types/TaxReportTypes';
import { OtherTaxReportColumn } from '../utils/TaxReportColumns';

const OtherTaxReport = ({
  setValue,
  value,
}: {
  setValue: React.Dispatch<React.SetStateAction<number>>;
  value: number;
}) => {
  const [form] = Form.useForm();
  const [date_range_value, setDate_range_value] = useState<string>();
  let totalDiscountAmount: number = 0;
  const [fetchTaxReport, { data, isLoading }] =
    useLazyGetAllOtherTaxReportQuery();
  if (data?.data) {
    for (let i = 0; i < data.data.length; i++) {
      totalDiscountAmount += Number(data.data[i].airticket_tax);
    }
  }
  const onFinish = async (values: ITaxReportFormDataType) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    setDate_range_value(date_range);
    const body = {
      date_range: date_range,
    };
    await fetchTaxReport({
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };

  //pagination--start---
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
  }, [data]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 1, isTrash: '0', pageSize: 20 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ['20', '50', '100', '200', '500'],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: ITaxReportFormDataType = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      date_range: date_range,
    };
    fetchTaxReport({
      query: `${query}&${body.date_range}`,
    });
  };
  //pagination--end---
  const toDate = dayjs().format('YYYY-MM-DD');
  useEffect(() => {
    const values = form.getFieldsValue();
    const date_range = `from_date=${toDate}&to_date=${toDate}`;
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
    form.setFieldValue('invoice_combclient_id', 'all');
    const body = {
      date_range: date_range,
      invoice_combclient_id: values.invoice_combclient_id,
    };
    fetchTaxReport({
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  }, []);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Expense_Report`,
  });

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  const optionsSummaryReport = [
    { label: 'Country Tax Report', value: 1 },
    { label: 'Tax Report', value: 0 },
  ];

  // @HANDLE CONTENTS
  const jsx_com = (
    <Table
      columns={OtherTaxReportColumn(queryData)}
      bordered
      size='small'
      dataSource={data?.data}
      loading={{ spinning: isLoading, indicator: loadingIndicator }}
      pagination={count > 20 ? tableParams.pagination : false}
      onChange={(args1, args2, args3) =>
        handleTableChange({
          args: {
            pagination: args1,
            filters: args2,
            sorter: args3,
          },
          states: {
            refetch,
            setQueryData,
            setTableParams,
            tableParams,
          },
        })
      }
    />
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Country Tax Report' }}
        extraInfo={{
          date:
            date_range_value
              ?.split('&')
              ?.map((param) => dayjs(param.split('=')[1])?.format('DD-MM-YYYY'))
              ?.join(' to ') || '',
        }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'Country Tax Report']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>

        <Radio.Group
          options={optionsSummaryReport}
          onChange={onChange}
          value={value}
          optionType='button'
          buttonStyle='solid'
        />
      </Space>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <ReportsHeader date_range required />
      </Form>

      <>
        <FormHeaderTitle title='Country Tax Report' />
        {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
        {jsx_com}
      </>

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default OtherTaxReport;
